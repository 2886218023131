import React, { PureComponent } from "react";
import { bool, func } from "prop-types";
import styled from "styled-components";
import { set } from "react-immutable-proptypes";

import FooterRightControls from "../modal/footer-right-controls";
import { TransparentButton } from "../button-v2";
import Modal from "../modal/modal";
import ColoredContentWrapper from "../colored-content-wrapper/colored-content-wrapper";
import Icon from "../icon/icon";
import Text from "../text/text";

const StyledSendEmailConfirmWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`;

const StyledIconWrapper = styled.div`
	padding-bottom: ${(props) => props.theme.spacing[3]};
`;

const StyledTextWrapper = styled.div`
	padding-top: ${(props) => props.theme.spacing[4]};
`;

const StyledOrderedList = styled.ol`
	padding-left: ${(props) => props.theme.spacing[4]};
	margin-bottom: 0;
`;

const StyledListItem = styled.li`
	margin-bottom: ${(props) => props.theme.spacing[3]};

	:last-child {
		margin-bottom: 0;
	}
`;

export default class SendEmailConfirm extends PureComponent {
	static propTypes = {
		isOpen: bool,
		onConfirm: func,
		onDecline: func,
		investorsStatus: set,
	};

	static defaultProps = {};

	onConfirm = () => {
		const { onConfirm } = this.props;

		onConfirm && onConfirm();
	};

	onDecline = () => {
		const { onDecline } = this.props;

		onDecline && onDecline();
	};

	renderButtons = () => {
		return (
			<FooterRightControls>
				<TransparentButton
					tid="shareholders.send_email_confirm.modal.continue"
					onClick={this.onConfirm}
				/>
				<TransparentButton
					tid="shareholders.send_email_confirm.modal.cancel"
					textColor="midGrey"
					onClick={this.onDecline}
				/>
			</FooterRightControls>
		);
	};

	render = () => {
		const { isOpen, investorsStatus } = this.props;
		const continueBtn = (
			<Text
				tid="shareholders.send_email_confirm.modal.info1.btn.continue"
				bold={700}
			/>
		);
		const cancelBtn = (
			<Text
				tid="shareholders.send_email_confirm.modal.info4.btn.cancel"
				bold={700}
			/>
		);

		return (
			<Modal
				title="shareholders.send_email_confirm.modal.title"
				isOpen={isOpen}
				footerComponent={this.renderButtons()}
				hSize="md"
				vSize={100}
			>
				<StyledSendEmailConfirmWrapper>
					<StyledColoredContentWrapper type="secondary">
						<Text tid="shareholders.send_email_confirm.modal.info5" />
						<StyledTextWrapper>
							<StyledOrderedList>
								{investorsStatus.map((status, index) => {
									return (
										<StyledListItem key={index}>
											<Text
												key={index}
												tid={`shareholders.send_email_confirm.modal.${status}`}
											/>
										</StyledListItem>
									);
								})}
							</StyledOrderedList>
						</StyledTextWrapper>
					</StyledColoredContentWrapper>

					<StyledColoredContentWrapper type="secondary">
						<StyledIconWrapper>
							<Icon
								icon="faExclamationTriangle"
								size="normal"
								color="yellowMid"
								type="solid"
							/>
						</StyledIconWrapper>
						<Text
							tid="shareholders.send_email_confirm.modal.info1"
							values={{ continueBtn }}
						/>
						<StyledTextWrapper>
							<Text tid="shareholders.send_email_confirm.modal.info2" />
						</StyledTextWrapper>
						<StyledTextWrapper>
							<Text tid="shareholders.send_email_confirm.modal.info3" />
						</StyledTextWrapper>
					</StyledColoredContentWrapper>

					<StyledColoredContentWrapper type="secondary">
						<StyledIconWrapper>
							<Icon
								icon="faExclamationTriangle"
								size="normal"
								color="yellowMid"
								type="solid"
							/>
						</StyledIconWrapper>
						<Text
							tid="shareholders.send_email_confirm.modal.info4"
							values={{ cancelBtn }}
						/>
					</StyledColoredContentWrapper>
				</StyledSendEmailConfirmWrapper>
			</Modal>
		);
	};
}
