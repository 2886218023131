/**
 * MainNav
 * @module components/menu/main_nav
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "../../interfaces/router";
import { Map } from "immutable";
import CollapsibleMenuItem from "./main-nav--collapsible-item";
import { fetchMenu } from "../../actions/menu.actions";
import { resetCompany, fetchCompany } from "../../actions/company.actions";

import Text from "../../dumb-components/shared/text/text";
import Button from "../../dumb-components/shared/button/button";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";

import ActivityBar from "../framework/activity-bar.js";
import ScrollView from "../../dumb-components/shared/layout/scroll-view/scroll-view";

import { openModal } from "../../actions/modals.actions";
import { COMPANY_VERIFICATION_MODAL } from "../../constants/modals";
import CompanyVerifyModalContainer from "../../containers/company/company-verify-modal.container";
import history from "../../interfaces/history";

import {
	EVENT_TYPE_COMPANY_OWNER_CHANGED,
	EVENT_TYPE_COMPANY_UPDATE,
	EVENT_TYPE_SHARES_INVESTMENT_LINKED,
} from "/shared/constants";

class MainNav extends Component {
	componentDidMount() {
		this.loadMenu();
	}

	componentDidUpdate = (prevProps) => {
		const { company, userCompanies } = this.props;

		if (userCompanies && prevProps.userCompanies !== userCompanies) {
			const companyId = company && company.id;
			const exist = userCompanies.find((company) => {
				return company.get("id") === companyId && !company.get("isCanceled");
			});

			if (!exist) {
				this.props.resetCompany();
			}
		}

		if (
			userCompanies &&
			!company &&
			prevProps.userCompanies !== userCompanies
		) {
			const filteredCompanies = userCompanies.filter((company) => {
				const notDemo = !company.get("isUserDemo");
				const notCanceled = !company.get("isCanceled");
				return notDemo && notCanceled;
			});

			// User has only one ACTIVE company (excluding demo and cancelled companies)
			if (filteredCompanies.size === 1) {
				const companyId = filteredCompanies.getIn([0, "id"]);
				this.props.fetchCompany(companyId, true);
			}
		}

		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const { audit, investorAudit, company } = this.props;
		const companyUpdate = audit.get(EVENT_TYPE_COMPANY_UPDATE, Map());
		const companyOwnerChanged = audit.get(
			EVENT_TYPE_COMPANY_OWNER_CHANGED,
			Map(),
		);

		if (
			companyUpdate.get("refresh") === true &&
			companyUpdate.hasIn(["metadata", "sharesInitialized"])
		) {
			this.loadMenu();
		} else if (
			companyUpdate.get("refresh") === true &&
			!companyUpdate.hasIn(["metadata", "sharesInitialized"])
		) {
			this.props.fetchCompany(company && company.id, true);
		}

		const SHARES_INVESTMENT_LINKED = investorAudit.get(
			EVENT_TYPE_SHARES_INVESTMENT_LINKED,
		);

		if (
			SHARES_INVESTMENT_LINKED &&
			SHARES_INVESTMENT_LINKED.get("refresh") === true
		) {
			this.loadMenu();
		}

		if (companyOwnerChanged.get("refresh")) {
			this.loadMenu();
		}
	};

	loadMenu = () => {
		this.props.fetchMenu("main");
	};

	goToCompanyLandingPage = () => {
		const { company, history } = this.props;
		history.push(`/${company.urlAlias}`);
	};

	openCompanyVerifyModal = () => {
		this.props.openModal(COMPANY_VERIFICATION_MODAL);
	};

	renderMenu(item) {
		let classes = "menu__body__list--item ";
		switch (item.level) {
			case 0:
				classes += "level--one ";
				break;
			case 1:
				classes += "level--two ";
				break;
			case 2:
				classes += "level--three ";
				break;
			default:
				classes += "level--two ";
				break;
		}

		if (item.children) {
			return (
				<CollapsibleMenuItem
					classes={classes}
					key={item.id}
					id={item.id}
					level={item.level}
					name={item.name}
					url={item.url}
					pathName={this.props.pathName}
					translationId={item.translationId}
					icon={item.icon}
				>
					{item.children.map(this.renderMenu.bind(this))}
				</CollapsibleMenuItem>
			);
		}
		if (item.disabled) {
			return (
				<li key={item.id} className={classes}>
					<Tooltip tid={item.disabledTid} delayShow="instant">
						<span className="text--menu__disabled menu__body__list__link">
							{item.translationId ? (
								<FormattedMessage id={item.translationId} />
							) : (
								item.name
							)}
						</span>
					</Tooltip>
				</li>
			);
		}

		if (!item.noContent) {
			return (
				<li key={item.id} className={classes}>
					<NavLink
						to={item.url}
						className="text--menu menu__body__list__link"
						activeClassName="text--menu--active"
					>
						{item.translationId ? (
							<FormattedMessage id={item.translationId} />
						) : (
							item.name
						)}
					</NavLink>
				</li>
			);
		}
	}

	render() {
		const { userResources, company, companyResources } = this.props;
		const hasCompanyResources = companyResources && companyResources.length > 0;
		const companyIsVerified = company ? company.verified : true;
		const companyNamePointer = companyIsVerified ? "pointer" : "default";

		return (
			<nav className="menu">
				<Link to="/" className="menu__header">
					<ActivityBar />
				</Link>

				<div className="menu__body">
					<ul className="menu__body__list">
						<div className="menu__user-area">
							{userResources && userResources.map(this.renderMenu.bind(this))}
						</div>

						{company && hasCompanyResources && (
							<div className="menu__company-area">
								<li
									className="menu__company-name"
									style={{ cursor: companyNamePointer }}
									onClick={
										companyIsVerified ? this.goToCompanyLandingPage : undefined
									}
								>
									<div>
										<span
											className="text text--company-name"
											onClick={
												!companyIsVerified
													? this.goToCompanyLandingPage
													: undefined
											}
										>
											{company.name}
										</span>
										{!companyIsVerified && (
											<React.Fragment>
												<br />
												<Tooltip
													tid="menu.company.title.tooltip.unverified"
													delayShow="instant"
												>
													<Button
														mode="link"
														onClick={this.openCompanyVerifyModal}
													>
														<Text
															color="red"
															bold={600}
															fontSize="15px"
															hasUnderline={true}
															tid="menu.company.title.btn.unverified"
														/>
													</Button>
												</Tooltip>
											</React.Fragment>
										)}
									</div>
									<div className="menu__arrow-left" />
								</li>

								<ScrollView
									showOnHover={true}
									scrollbarColor="lightGrey"
									noLeftMargin
								>
									<div className="i-content__container--menu">
										{hasCompanyResources &&
											companyResources.map(this.renderMenu.bind(this))}
									</div>
								</ScrollView>
							</div>
						)}
					</ul>
				</div>

				{!companyIsVerified && <CompanyVerifyModalContainer />}
			</nav>
		);
	}
}

function mapStateToProps(state) {
	return {
		history: history,
		userResources: state.menu.userResources,
		companyResources: state.menu.companyResources,
		company: state.company.company,
		userCompanies: state.company.companies,
		audit: state.audit.get("company"),
		investorAudit: state.audit.get("investors"),
	};
}

const mapDispatchToProps = {
	fetchMenu,
	resetCompany,
	fetchCompany,
	openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNav);
