/* Modal Widths */
export const SM = 300;
export const MD = 600;
export const LM = 720;
export const LG = 900;
export const XL = 1200;

// Modal with list of all tempaltes
export const MEETING_TEMPLATES_MODAL = "MEETING_TEMPLATES_MODAL";

// Modal with attendees that is triggered when meeting is about to get start
export const MEETING_START_MEETING_MODAL = "MEETING_START_MEETING_MODAL";

// Simple modal that is visible for an second or two and shows some text.
export const SIMPLE_CONFIRMATION_MODAL = "SIMPLE_CONFIRMATION_MODAL";

// Modal that shows up when admin request document signing to select who should sign the document
export const DOCUMENT_ESIGN_USERS_MODAL = "DOCUMENT_ESIGN_USERS_MODAL";

// Same as above but with additional configuration options
export const DOCUMENT_REQUEST_ESIGN_MODAL_ADVANCED =
	"DOCUMENT_REQUEST_ESIGN_MODAL_ADVANCED";

// Move meeting to another group modal
export const MEETING_MOVE_MEETING_MODAL = "MEETING_MOVE_MEETING_MODAL";

// Move document to another folder modal
export const DOCUMENT_MOVE_DOCUMENT_MODAL = "DOCUMENT_MOVE_DOCUMENT_MODAL";

// Move task to another category modal
export const TASK_MOVE_TASK_MODAL = "TASK_MOVE_TASK_MODAL";

// Open when verifing the company after skipping the process on initial setup
export const COMPANY_VERIFICATION_MODAL = "COMPANY_VERIFICATION_MODAL";

// Protocol PDF Options Modal
export const PROTOCOL_PDF_OPTIONS_MODAL = "PROTOCOL_PDF_OPTIONS_MODAL";

// Select a meeting to link to
export const MEETINGS_LINK_MODAL = "MEETINGS_LINK_MODAL";

// Attendee Settings
export const MEETINGS_ATTENDEE_SETTINGS_MODAL =
	"MEETINGS_ATTENDEE_SETTINGS_MODAL";

// Rollcall meetings
export const MEETINGS_ROLLCALL_MODAL = "MEETINGS_ROLLCALL_MODAL";

// Shows up when requesting document download and the document is signed.
// Allows to choose between downloading an signed or unsigned document
export const DOCUMENT_DOWNLOAD_SIGNED_MODAL = "DOCUMENT_DOWNLOAD_SIGNED_MODAL";

// Add share type modal. Avaible while in TRANSACTIONS
export const SHARES_SHARE_TYPE_MODAL = "SHARES_SHARE_TYPE_MODAL";

// Add shareholder modal, triggered from shares. Avaible in TRANSACTIONS
export const SHARES_ADD_SHAREHOLDER_MODAL = "SHARES_ADD_SHAREHOLDER_MODAL";

// Image Crop Modal (User/Company Profile)
export const IMAGE_CROP_MODAL = "IMAGE_CROP_MODAL";

// Filter by certificate modal in Dashboard
export const SHAREBOOK_FILTER_BY_TRANSACION = "SHAREBOOK_FILTER_BY_TRANSACION";

// Display's information modal when user is trying to sign an document
// and there are no funds to proceed with BankID signing
export const INSUFFICIENT_FUNDS_DOCUMENT_ESIGN_MODAL =
	"INSUFFICIENT_FUNDS_DOCUMENT_ESIGN_MODAL";

// A modal with information about the eSigning balance that shows after creation  of a subscription
export const SUBSCRIPTION_AFTER_SUB_POT_INFO =
	"SUBSCRIPTION_AFTER_SUB_POT_INFO";

// Transfer folder
export const DOCUMENT_MOVE_FOLDER_MODAL = "DOCUMENT_MOVE_FOLDER_MODAL";

// Add new company modal
export const COMPANY_ADD_NEW_COMPANY_MODAL = "COMPANY_ADD_NEW_COMPANY_MODAL";

// Manage file
export const MEETINGS_ATTACHMENT_SETTINGS_MODAL =
	"MEETINGS_ATTACHMENT_SETTINGS_MODAL";

export const MEETINGS_START_STANDARD_MEETING_MODAL =
	"MEETINGS_START_STANDARD_MEETING_MODAL";

export const MEETINGS_START_STANDARD_MEETING_ALTERNATIVE_MODAL =
	"MEETINGS_START_STANDARD_MEETING_ALTERNATIVE_MODAL";

export const MEETINGS_END_MEETING_MODAL = "MEETINGS_END_MEETING_MODAL";

export const MEETINGS_ATTENDEE_FUNCTIONS_MANAGE_MODAL =
	"MEETINGS_ATTENDEE_FUNCTIONS_MANAGE_MODAL";

export const MEETINGS_VOTING_DELETE_CONFIRM_MODAL =
	"MEETINGS_VOTING_DELETE_CONFIRM_MODAL";

export const MEETINGS_PROXY_MANAGE_DOCUMENT = "MEETINGS_PROXY_MANAGE_DOCUMENT";

export const MEETINGS_ADD_PROXY_MODAL = "MEETINGS_ADD_PROXY_MODAL";

export const COLLECTIONS_MANAGE_MODAL = "COLLECTIONS_MANAGE_MODAL";
export const COLLECTIONS_SHARE_MODAL = "COLLECTIONS_SHARE_MODAL";
export const COLLECTIONS_MERGE_PDF_MODAL = "COLLECTIONS_MERGE_PDF_MODAL";
export const COLLECTIONS_MANAGE_SHARED_MODAL =
	"COLLECTIONS_MANAGE_SHARED_MODAL";
export const MEETINGS_MANAGE_MODERATORS = "MEETINGS_MANAGE_MODERATORS";

// Grid modal
export const SHARE_ISSUE_GRID_MODAL = "SHARE_ISSUE_GRID_MODAL";

export const GRID_MODAL = "GRID_MODAL";

// Sending custom emails
export const INVITE_USER_BY_EMAIL_TEMPLATE_STEPS_MODAL =
	"INVITE_USER_BY_EMAIL_TEMPLATE_STEPS_MODAL";
export const REMIND_USER_BY_EMAIL_TEMPLATE_MODAL =
	"REMIND_USER_BY_EMAIL_TEMPLATE_MODAL";
export const INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL =
	"INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL";
export const REMIND_SHAREHOLDER_EMAIL_TEMPLATE_MODAL =
	"REMIND_SHAREHOLDER_EMAIL_TEMPLATE_MODAL";
export const REMIND_SHAREHOLDERS_EMAIL_TEMPLATE_MODAL =
	"REMIND_SHAREHOLDERS_EMAIL_TEMPLATE_MODAL";

// Modal for showing errors when importing share data
export const MODALS_SHARES_ERROR_MODAL = "MODALS_SHARES_ERROR_MODAL";

// managing depos
export const MANAGE_DEPO_MODAL = "MANAGE_DEPO_MODAL";

// Investments overview
export const ATTENDEES_CONFIRM_INVITE_MODAL = "ATTENDEES_CONFIRM_INVITE_MODAL";
export const INVESTMENTS_OVERVIEW_PRIVATE_MODE_ADD_INVESTMENT_MODAL =
	"INVESTMENTS_OVERVIEW_PRIVATE_MODE_ADD_INVESTMENT_MODAL";

export const CONFIRM_DELETE_PROXY = "CONFIRM_DELETE_PROXY";

export const CONFIRM_DELETE_PROTOCOL = "CONFIRM_DELETE_PROTOCOL";

export const CONFIRM_DELETE_DOCUMENT = "CONFIRM_DELETE_DOCUMENT";
export const CONFIRM_DELETE_SIGNED_DOCUMENT = "CONFIRM_DELETE_SIGNED_DOCUMENT";
export const CONFIRM_DELETE_DOCUMENT_SIGNING_PROCESS_ONGOING =
	"CONFIRM_DELETE_DOCUMENT_SIGNING_PROCESS_ONGOING";

export const ADD_DOCUMENT_TO_COLLECTION_MODAL =
	"ADD_DOCUMENT_TO_COLLECTION_MODAL";

export const ADD_EXISTING_DOCUMENT_TO_COLLECTION_MODAL =
	"ADD_EXISTING_DOCUMENT_TO_COLLECTION_MODAL";

export const MY_RECORDS_ADD_TRANSACTION_MODAL =
	"MY_RECORDS_ADD_TRANSACTION_MODAL";
export const MY_RECORDS_EDIT_TRANSACTION_MODAL =
	"MY_RECORDS_EDIT_TRANSACTION_MODAL";
export const MY_RECORDS_CONFIGURE_PORTFOLIO_TABLE_DATA_MODAL =
	"MY_RECORDS_CONFIGURE_PORTFOLIO_TABLE_DATA_MODAL";

export const CAP_TABLE_READ_MORE_MODAL = "CAP_TABLE_READ_MORE_MODAL";

export const COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS =
	"COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS";
export const PRIVATE_ADDRESS_BOOK_MODAL = "PRIVATE_ADDRESS_BOOK_MODAL";

export const PERSONAL_ATTACHMENTS_MODAL = "PERSONAL_ATTACHMENTS_MODAL";

// Chart modals
export const CHART_MODAL = "CHART_MODAL";

export const MODELS_RECOMMEND_INVONO = "MODELS_RECOMMEND_INVONO";

// Dealflow
export const DEALFLOW_USER_CRITERIA_LISTS_MODAL =
	"DEALFLOW_USER_CRITERIA_LISTS_MODAL";
export const DEALFLOW_USER_CRITERIA_MODAL = "DEALFLOW_USER_CRITERIA_MODAL";
export const DEALFLOW_EDIT_USER_CRITERIA_MODAL =
	"DEALFLOW_EDIT_USER_CRITERIA_MODAL";
export const DEALFLOW_ADMINISTRATION_CRITERIA_MODAL =
	"DEALFLOW_ADMINISTRATION_CRITERIA_MODAL";

export const COPY_CONTACT_TO_COMPANY_MODAL = "COPY_CONTACT_TO_COMPANY_MODAL";

export const MEETING_TEMPLATES_IMPORTANT_INFORMATION_MODAL =
	"MEETING_TEMPLATES_IMPORTANT_INFORMATION_MODAL";
export const MEETING_SELECT_TEMPLATE_MODAL = "MEETING_SELECT_TEMPLATE_MODAL";
export const MEETINGS_SMART_INVITE_NO_TEMPLATE_MODAL =
	"MEETINGS_SMART_INVITE_NO_TEMPLATE_MODAL";

export const MEETINGS_UNPUBLISH_PROTOCOL_MODAL =
	"MEETINGS_UNPUBLISH_PROTOCOL_MODAL";
export const DOCUMENTS_ADVANCED_FILTER_MODAL =
	"DOCUMENTS_ADVANCED_FILTER_MODAL";

// Promotion
export const DOCUMENTS_PROMOTION_MODAL_ESIGNING =
	"DOCUMENTS_PROMOTION_MODAL_ESIGNING";

export const DOCUMENTS_ESIGNING_PASSWORD_PROTECTED_PDF_MODAL =
	"DOCUMENTS_ESIGNING_PASSWORD_PROTECTED_PDF_MODAL";
export const COMPANY_INVESTMENTS_LIMITATIONS_MODAL =
	"COMPANY_INVESTMENTS_LIMITATIONS_MODAL";
export const APPEAL_TO_COMPANY_MODAL = "APPEAL_TO_COMPANY_MODAL";
