// Created January 2024 to replace "./request.module.js"
// Intented to be used with react-query

import axios from "axios";
import Cookies from "universal-cookie";
import { getStore } from "../store";

const store = getStore();

const axiosInstance = axios.create({
	baseURL: "/api",
	timeout: 300000,
});

axiosInstance.interceptors.request.use((config) => {
	const accessToken = new Cookies().get("accessToken");
	const publicAccessToken = localStorage.getItem("publicAccessToken");
	const state = store.getState();

	if (state.company.company) {
		config.headers["x-company-id"] = state.company.company.id;
	}

	if (accessToken) {
		config.headers["authorization"] = `Bearer ${accessToken}`;
	}

	if (location.pathname.includes("/public") && publicAccessToken) {
		config.headers["authorization"] = `Bearer ${localStorage.getItem(
			"publicAccessToken",
		)}`;
	}

	return config;
});

export default axiosInstance;
