import React from "react";
import { Card, CardContent, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconButton from "../../../mui-components/button/icon-button";
import { useDispatch } from "react-redux";
import { openModal } from "../../../actions/modals.actions";
import { DOCUMENTS_PROMOTION_MODAL_ESIGNING } from "../../../constants/modals";
import { Signature } from "lucide-react";
import Panel from "@/dumb-components/shared/panel/panel";

const FreeEsignPanel = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<Panel
			icon={<Signature />}
			title="document.esign.signatories.panel.title"
			className="!mb-6"
		>
			<div className="p-6">
				<Card variant="dark">
					<CardContent
						variant="flex"
						sx={{
							p: "16px!important",
							gap: 0,
							alignItems: "center",
						}}
					>
						<Typography variant="body2">
							{t("documents.document.esign.panel.information_before")}
						</Typography>
						<Box sx={{ gap: 0.5, display: "flex" }}>
							<IconButton
								icon="faPlus"
								color="white"
								onClick={() =>
									dispatch(openModal(DOCUMENTS_PROMOTION_MODAL_ESIGNING))
								}
							/>

							<IconButton icon="faEllipsisV" color="white" disabled />
						</Box>
					</CardContent>
				</Card>
			</div>
		</Panel>
	);
};

export default FreeEsignPanel;
