/**
 * index.js
 * Initialization for React and the entire app.
 * @see {@link http://usejsdoc.org} for information about comments standard used.
 */

import "./assets/css/index.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Router } from "./interfaces/router";
import history from "./interfaces/history";
import ConnectedIntlProvider from "./i18n/ConnectedIntlProvider";
import Cookies from "universal-cookie";
import { getStore } from "./store";
import { ThemeProvider } from "styled-components";
import theme from "../theme";
import { ModalProvider } from "styled-react-modal";
import StyledModalBackground from "./dumb-components/shared/modal-background/modal-background";
import i18n from "./i18n"; // initialized i18next instance
import { I18nextProvider } from "react-i18next";
import { setLanguage } from "./actions/i18n.actions";
import ReactGA from "react-ga4";
import { setAuthenticated } from "./actions/credentials.actions";
import App from "./components/app";
import { MUItheme } from "../MUItheme";
import { Settings } from "luxon";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ConfirmProvider } from "./containers/shared/confirm/confirm.provider";
// import { PromotionContextProvider } from './dumb-components/promoting/context/PromotionContextProvider';
import queryClient from "./queryClient";

// Expose Google Cloud Builder variables (if any) to the window object
window.REF_NAME = process.env.REF_NAME;
window.COMMIT_SHA = process.env.COMMIT_SHA;

// Create a redux store
const store = getStore();

// Polyfill Intl
if (!window.Intl) {
	window.Intl = require("intl");
}

// See if we have an JSON Web Token (then the user is logged in).
const accessToken = new Cookies().get("accessToken");
if (accessToken) {
	store.dispatch(setAuthenticated(true));
}

const userlanguage = new Cookies().get("userLanguage");
store.dispatch(setLanguage(userlanguage));
Settings.defaultZoneName = "Europe/Stockholm";

// Google analytics
ReactGA.initialize("UA-99828571-2");
history.listen((location) => {
	ReactGA.send({
		hitType: "pageview",
		path: location.pathname + location.search,
	});
});

// Start things up...
createRoot(document.getElementById("app")).render(
	<>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<ConnectedIntlProvider>
					<I18nextProvider i18n={i18n}>
						<MUIThemeProvider theme={MUItheme}>
							<ThemeProvider theme={theme}>
								<LocalizationProvider
									dateAdapter={AdapterLuxon}
									adapterLocale={"sv-SE"}
								>
									{/*<GlobalStyle>*/}
									<ModalProvider backgroundComponent={StyledModalBackground}>
										{/* <PromotionContextProvider> */}
										<ConfirmProvider>
											<Router history={history}>
												<App />
											</Router>
										</ConfirmProvider>
										{/* </PromotionContextProvider> */}
									</ModalProvider>
									{/*</GlobalStyle>*/}
								</LocalizationProvider>
							</ThemeProvider>
						</MUIThemeProvider>
					</I18nextProvider>
				</ConnectedIntlProvider>
			</Provider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</>,
);

// Prevent droping files anywhere where you shouldn't
document.addEventListener("drop", (e) => {
	e.preventDefault();
});
document.addEventListener("dragover", (e) => {
	e.preventDefault();
});

/**
 * Hide/remove loading screen
 * @private
 */
function _removeLoadingScreen() {
	const loadingScreen = document.getElementById("loading-screen");

	if (loadingScreen.classList) {
		loadingScreen.classList.add("is-fading-out");
	} else {
		loadingScreen.className += " is-fading-out";
	}
	setTimeout(() => {
		loadingScreen.remove();
	}, 350);
}
_removeLoadingScreen();
