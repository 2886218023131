import React, { useEffect, useState } from "react";
import AgGrid from "../../shared/ag-grid/ag-grid";
import {
	GRID_MODE_PANEL,
	SHARED_FOLDERS_ID,
	EVENT_TYPE_DOCUMENT_CREATE,
	EVENT_TYPE_DOCUMENT_UPDATE,
	EVENT_TYPE_DOCUMENT_DELETE,
	EVENT_TYPE_DOCUMENT_TRANSFER,
} from "/shared/constants";
import { useDispatch, useSelector } from "react-redux";
import { documentListColumnDefs } from "./column-defs";
import history, { getQuery } from "../../../interfaces/history";
import {
	listDocumentsByFilter,
	onSelectDocument,
} from "../../../actions/documents.actions";
import { string } from "prop-types";
import { sharingListDocuments } from "../../../actions/documents-sharing.actions";
import SharedMainFolderAlert from "../document-list/shared-main-folder-alert";
import EmptyListMessage from "../document-list/empty-list-message";
import { useTranslation } from "react-i18next";

const DocumentListGrid = ({ basePath, querystr, gridRef, documentId }) => {
	const documents = useSelector((store) => store.documents.get("allDocuments"));
	const sharedDocuments = useSelector((store) =>
		store.documents.get("sharedDocuments"),
	);
	const folderId = useSelector(
		(store) => store.folders.get("selectedFolderId", "") || "",
	);
	const filters = useSelector((store) => store.documents.get("filterBy"));

	const audit = useSelector((state) => state.audit.get("documents"));
	const EVENT_DOCUMENT_CREATE = audit.get(EVENT_TYPE_DOCUMENT_CREATE);
	const EVENT_DOCUMENT_UPDATE = audit.get(EVENT_TYPE_DOCUMENT_UPDATE);
	const EVENT_DOCUMENT_DELETE = audit.get(EVENT_TYPE_DOCUMENT_DELETE);
	const EVENT_DOCUMENT_TRANSFER = audit.get(EVENT_TYPE_DOCUMENT_TRANSFER);

	const query = getQuery();

	const { t } = useTranslation();

	const [colDefs, setColDefs] = useState(null);
	const [rowData, setRowData] = useState(null);
	const ROWS_PER_PAGE = 100;
	const [firstDataRenderedApi, setFirstDataRenderedApi] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		setColDefs(documentListColumnDefs(t));
	}, []);

	useEffect(() => {
		const isSharedFromCompanyId = query.isSharedFromCompanyId;
		const folderInQuerystring = query.folder || "";

		if (isSharedFromCompanyId) {
			dispatch(sharingListDocuments(isSharedFromCompanyId));
		} else if (folderId === folderInQuerystring) {
			dispatch(listDocumentsByFilter(filters, folderId));
		}
	}, [folderId, filters]);

	useEffect(() => {
		const withinSharedFolder = getIsWithinSharedFolder();

		if (withinSharedFolder && sharedDocuments && sharedDocuments.size > 0) {
			const _sharedDocuments = sharedDocuments.map((document) => {
				document = document.set("basePath", basePath);
				return document;
			});
			setRowData(_sharedDocuments.toJS());
			return;
		}

		if (documents && documents.size > 0) {
			let _documents = documents;

			if (_documents.size > 0) {
				_documents = _documents.map((document) => {
					document = document.set("basePath", basePath);
					if (document.get("folderId") !== folderId && folderId !== "") {
						document = document.set("group", "documents.list.sub_folder_files");
					}
					return document;
				});

				setRowData(_documents.toJS());
			} else {
				setRowData([]);
			}
		} else {
			setRowData([]);
		}
	}, [documents, folderId, sharedDocuments]);

	useEffect(() => {
		if (firstDataRenderedApi) {
			const currentExists =
				gridRef.current && Object.keys(gridRef.current).size > 0;
			const _api = currentExists ? gridRef.current.api : firstDataRenderedApi;
			const hasRowData = rowData && rowData.length > 0;

			if (documentId && hasRowData) {
				const index = rowData.findIndex((row) => row.id === documentId);
				const node = _api.getRowNode(index);
				node && node.setSelected(true);
			} else {
				_api.deselectAll();
			}
		}
	}, [gridRef.current, rowData, firstDataRenderedApi, documentId]);

	const fetchDocuments = (isSharedObject, objectOwnerCompanyId) => {
		const folderInQuerystring = query.folder || "";
		const isSharedFromCompanyId = query.isSharedFromCompanyId;

		if (isSharedFromCompanyId && isSharedObject) {
			dispatch(sharingListDocuments(objectOwnerCompanyId, true));
		} else if (folderId === folderInQuerystring) {
			dispatch(listDocumentsByFilter(filters, folderId));
		}
	};

	useEffect(() => {
		if (EVENT_DOCUMENT_CREATE && EVENT_DOCUMENT_CREATE.get("r") === true) {
			const isSharedObject = EVENT_DOCUMENT_CREATE.getIn([
				"metadata",
				"isSharedObject",
			]);
			const objectOwnerCompanyId = EVENT_DOCUMENT_CREATE.getIn([
				"metadata",
				"objectOwnerCompanyId",
			]);
			fetchDocuments(isSharedObject, objectOwnerCompanyId);
		}
	}, [EVENT_DOCUMENT_CREATE]);

	useEffect(() => {
		if (EVENT_DOCUMENT_UPDATE && EVENT_DOCUMENT_UPDATE.get("r") === true) {
			const isSharedObject = EVENT_DOCUMENT_UPDATE.getIn([
				"metadata",
				"isSharedObject",
			]);
			const objectOwnerCompanyId = EVENT_DOCUMENT_UPDATE.getIn([
				"metadata",
				"objectOwnerCompanyId",
			]);
			fetchDocuments(isSharedObject, objectOwnerCompanyId);
		}
	}, [EVENT_DOCUMENT_UPDATE]);

	useEffect(() => {
		if (EVENT_DOCUMENT_DELETE && EVENT_DOCUMENT_DELETE.get("r") === true) {
			const isSharedObject = EVENT_DOCUMENT_DELETE.getIn([
				"metadata",
				"isSharedObject",
			]);
			const objectOwnerCompanyId = EVENT_DOCUMENT_DELETE.getIn([
				"metadata",
				"objectOwnerCompanyId",
			]);
			fetchDocuments(isSharedObject, objectOwnerCompanyId);
		}
	}, [EVENT_DOCUMENT_DELETE]);

	useEffect(() => {
		if (EVENT_DOCUMENT_TRANSFER && EVENT_DOCUMENT_TRANSFER.get("r") === true) {
			const isSharedObject = EVENT_DOCUMENT_TRANSFER.getIn([
				"metadata",
				"isSharedObject",
			]);
			const objectOwnerCompanyId = EVENT_DOCUMENT_TRANSFER.getIn([
				"metadata",
				"objectOwnerCompanyId",
			]);
			fetchDocuments(isSharedObject, objectOwnerCompanyId);
		}
	}, [EVENT_DOCUMENT_TRANSFER]);

	const getIsWithinSharedFolder = () => {
		const query = getQuery();
		const withinSharedFolder =
			query && query.isSharedFromCompanyId ? true : false;
		return withinSharedFolder;
	};

	const onRowClicked = (event) => {
		const { id } = event.data;
		const path = `${basePath}/${id}${querystr}`;

		dispatch(onSelectDocument(id));
		history.push(path);
	};

	const gridOptions = {
		rowHeight: 54,
		rowStyle: {
			maxHeight: "50px",
			borderRadius: "0px",
		},
		// rowStyle: {
		// 	borderBottom: `${MUItheme.palette.solitude.light} 2px solid`,
		// 	borderTop: `${MUItheme.palette.solitude.light} 2px solid`,
		// },
	};

	const onFirstDataRendered = (params) => {
		setFirstDataRenderedApi(params.api);
	};

	const isMainSharingFolder = SHARED_FOLDERS_ID === folderId;
	if (isMainSharingFolder) {
		return <SharedMainFolderAlert />;
	}

	if (folderId && !documents?.size && !sharedDocuments?.size) {
		return <EmptyListMessage />;
	}

	if (!rowData || rowData.length === 0) {
		return null;
	}

	return (
		<AgGrid
			mode={GRID_MODE_PANEL}
			gridRef={gridRef}
			rowData={rowData}
			columnDefs={colDefs}
			headerHeight={0}
			documentList={true}
			forcedHeight={"100%"}
			gridOptions={gridOptions}
			rowsPerPage={ROWS_PER_PAGE}
			pagination={true}
			onRowClicked={onRowClicked}
			onFirstDataRendered={onFirstDataRendered}
			groupedGrid={true}
			groupDisplayType="groupRows"
			isGroupOpenByDefault={() => true}
			groupRowRenderer="agGroupCellRenderer"
			groupRowRendererParams={{ suppressCount: true }}
			rowGroupHeaderMode="light"
		/>
	);
};

DocumentListGrid.propTypes = {
	basePath: string,
	querystr: string,
	documentId: string,
};

export default DocumentListGrid;
