import React, { PureComponent } from "react";
import { bool, string, func, oneOf, object } from "prop-types";
import styled from "styled-components";

import DropdownItem from "./dropdown-item";
import Text from "../text/text";
import Icon from "../icon/icon";
import NotificationBadge from "../notification-badge/notification-badge";

const StyledWrapper = styled.div`
	display: flex;
	padding: 0 ${(props) => props.theme.spacing[4]};
	flex-direction: row;
	align-items: center;
	flex: 1;
`;

const StyledIconWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 28px;
	padding: ${(props) => props.padding};
`;

const StyledTextWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
	flex: 1;
`;

const StyledRightComponent = styled.div`
	text-align: right;
`;

class DropdownIconItem extends PureComponent {
	render = () => {
		const {
			onClick,
			disabled,
			icon,
			tid,
			tidValues,
			iconType,
			children,
			iconColor,
			iconSpin,
			rightComponent,
			notificationBadgeColor,
			notificationBadgeProps: defaultNotificationBadgeProps,
			padding,
		} = this.props;
		const componentIconColor = disabled
			? "muted"
			: iconColor
			? iconColor
			: "midGrey";
		const dropdownItemProps = {
			noHorPadding: true,
			overflowHidden: true,
			onClick,
			disabled,
		};

		const notificationBadgeProps = {
			active: Boolean(notificationBadgeColor),
			color: notificationBadgeColor,
			x: 15,
			y: 5,
			...defaultNotificationBadgeProps,
		};

		return (
			<NotificationBadge {...notificationBadgeProps}>
				<DropdownItem {...dropdownItemProps}>
					<StyledWrapper>
						<StyledIconWrapper padding={padding}>
							<Icon
								icon={icon}
								spin={iconSpin}
								size="sml"
								type={iconType}
								color={componentIconColor}
							/>
						</StyledIconWrapper>
						<StyledTextWrapper>
							{tid && <Text tid={tid} values={tidValues} singleLine />}
							{!tid && children}
						</StyledTextWrapper>
						{rightComponent && (
							<StyledRightComponent>{rightComponent}</StyledRightComponent>
						)}
					</StyledWrapper>
				</DropdownItem>
			</NotificationBadge>
		);
	};
}

DropdownIconItem.defaultProps = {
	disabled: false,
	iconType: "light",
	tidAsHtml: false,
	padding: 0,
};

DropdownIconItem.propTypes = {
	disabled: bool,
	tid: string,
	icon: string,
	iconType: oneOf(["light", "solid"]),
	onClick: func,
	tidAsHtml: bool,
	rightComponent: object,
	notificationBadgeProps: object,
};

export default DropdownIconItem;
