import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	cancelEmission,
	createEmission,
	getAllEmissions,
	permanentlyCancelEmission,
	sendEmissionCancellationEmail,
} from "../../../actions/emissions.actions";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useHistory, useParams } from "react-router-dom";
import EmissionsBaseLayout from "./emission-base-layout";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EmissionCreationModal from "./emission-creation-modal";
import { useTranslation } from "react-i18next";
import FullPageSpinner from "../common/full-page-spinner";
import { DateTime } from "luxon";
import { displayEmissionType } from "../utils/display-helpers";
import Tooltip from "@mui/material/Tooltip";
import {
	emissionCanBeCancelled,
	emissionCanBePermanentlyCancelled,
} from "../utils/emission-status-helper";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import emissions from "../../../services/emissions";
import { emissionConsts } from "../../../constants/emissions";
import RichTextEditor from "../../../dumb-components/shared/editorv2/rich-text-editor";

const EmissionsForAdmin = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const { company } = useParams();
	const companyId = useSelector((state) => state.company?.company?.id);
	const emissions = useSelector((state) => state.emissions.all);
	const shareRegister = useSelector(
		(state) => state.company.company.metadata?.sharesInitialized,
	);
	const [showForm, setShowForm] = useState(false);
	const [loading, setLoading] = useState(false);
	const [activeEmissionExist, setActiveEmissionExist] = useState(false);
	const [showEmissionListHeader, setShowEmissionListHeader] = useState(false);
	const dispatch = useDispatch();
	const {
		common: { emissionStatus },
	} = emissionConsts;

	const getAll = useCallback(async () => {
		if (companyId) {
			try {
				setLoading(true);
				await dispatch(getAllEmissions(companyId));
			} finally {
				setLoading(false);
			}
		}
	}, [companyId, emissions]);

	useEffect(() => {
		getAll();
	}, [companyId, activeEmissionExist]);

	useEffect(() => {
		if (emissions.length > 0) {
			setShowEmissionListHeader(true);
			if (
				emissions.find((e) =>
					[
						emissionStatus.active,
						emissionStatus.invitationsSent,
						emissionStatus.partiallyClosed,
						emissionStatus.preparationsApproved,
					].includes(e.status),
				)
			) {
				setActiveEmissionExist(true);
			} else {
				setActiveEmissionExist(false);
			}
		} else {
			setShowEmissionListHeader(false);
		}
	}, [emissions]);

	return (
		<EmissionsBaseLayout>
			<Grid container>
				<Grid item xs={12}>
					<Box>
						<Typography variant="overline" sx={{ fontSize: "10px" }}>
							{t("emissions.mini-header.cap-table-admin")}
						</Typography>
						<Typography variant="h3">
							{t("emissions.header.emissions-module")}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={6} sx={{ pt: 2 }}>
					<Box>{t("emissions.long-desc.emissions-module")}</Box>
					{!shareRegister ? (
						<Box sx={{ mt: 2, fontSize: "14px", fontWeight: "bold" }}>
							<Typography variant="h6">
								{t("emission.create-emission.share-register.warning")}
							</Typography>
						</Box>
					) : (
						""
					)}
				</Grid>
				<Grid item xs={6}>
					<div
						style={{
							height: "100%",
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Tooltip
							title={
								activeEmissionExist
									? t("emission.create-emission.active-emission.warning")
									: ""
							}
						>
							<div style={{ display: "flex", alignItems: "flex-end" }}>
								<Button
									disabled={!shareRegister || activeEmissionExist}
									variant="contained"
									size="large"
									onClick={() => setShowForm(true)}
								>
									<Typography variant="h5">
										{t("emissions.create-emission")}
									</Typography>
								</Button>
							</div>
						</Tooltip>
					</div>
				</Grid>
			</Grid>
			<Grid container sx={{ mt: 4 }}>
				<Grid item xs={12}>
					<Box
						sx={{
							bgcolor: "background.paper",
							borderTopLeftRadius: 4,
							borderTopRightRadius: 4,
						}}
					>
						<List disablePadding>
							<ListItem disablePadding>
								{showEmissionListHeader && <EmissionHeader />}
							</ListItem>
							{emissions.map((e, i) => (
								<div key={i}>
									{i !== 0 && <Divider />}
									<ListItem disablePadding>
										<EmissionItem item={e} />
									</ListItem>
								</div>
							))}
						</List>
					</Box>
					{loading && <FullPageSpinner text={"Laddar nyemissioner ..."} />}
				</Grid>
			</Grid>
			<EmissionCreationModal
				open={showForm}
				close={() => setShowForm(false)}
				onSubmit={async (values, callback) => {
					try {
						await dispatch(
							createEmission(companyId, values, (emissionId) =>
								history.push(
									`/${company}/shares/cap-table/emission/${emissionId}/preparations`,
								),
							),
						);
						setShowForm(false);
					} catch (error) {
						// no need to handle the error since error message is already shown
						callback(error);
					}
				}}
				title={t("emissions.create-emission")}
				buttonText={t("emissions.create-emission")}
			/>
		</EmissionsBaseLayout>
	);
};

const commonStyle = {
	display: "flex",
	height: "100%",
	alignItems: "center",
	pl: 1,
	pr: 1,
};

const EmissionHeader = () => {
	const { t } = useTranslation();
	return (
		<Grid
			container
			sx={{
				pl: 3,
				pr: 1,
				py: 2,
				fontWeight: "bold",
				bgcolor: "blue.300",
				borderTopLeftRadius: 4,
				borderTopRightRadius: 4,
				height: "100%",
				alignItems: "center",
			}}
		>
			<Grid item xs={3}>
				<Box sx={commonStyle}>{t("emissions.column.name")}</Box>
			</Grid>
			<Grid item xs={2}>
				<Box sx={commonStyle}>{t("emissions.column.type")}</Box>
			</Grid>
			<Grid item xs={2}>
				<Box sx={commonStyle}>{t("emissions.column.created")}</Box>
			</Grid>
			<Grid item xs={2}>
				<Box sx={commonStyle}>{t("emissions.column.rest-capital")}</Box>
			</Grid>
			<Grid item xs={3}>
				<Box sx={{ ...commonStyle }}>{t("emissions.column.status")}</Box>
			</Grid>
		</Grid>
	);
};

const getDisplayStatus = (status) =>
	({
		cancelled: "emissions.status.cancelled",
		active: "emissions.status.started",
		invitationsSent: "emissions.status.invitationsSent",
		partiallyClosed: "emissions.status.partiallyClosed",
		completed: "emissions.status.completed",
		registered: "emissions.status.registered",
		preparationsApproved: "emissions.status.started",
	})[status] || "emissions.status.unknown";

const getDisplayStatusColor = (status) =>
	({
		active: ["#D7ECFD", "#164062"],
		invitationsSent: ["#D7ECFD", "#164062"],
		partiallyClosed: ["#D7ECFD", "#164062"],
		completed: ["success.light", "success.dark"],
		registered: ["success.light", "success.dark"],
		preparationsApproved: ["#D7ECFD", "#164062"],
	})[status] || ["", ""];

const EmissionItem = ({ item }) => {
	const history = useHistory();
	const { t } = useTranslation();
	const { company } = useParams();

	return (
		<Box
			sx={{
				cursor: "pointer",
				p: 0,
				flex: 1,
				"&:hover": { backgroundColor: "grey.200" },
			}}
			onClick={() =>
				history.push(
					`/${company}/shares/cap-table/emission/${item.id}/preparations`,
				)
			}
		>
			<Grid container sx={{ pl: 3, pr: 1, py: 1 }}>
				<Grid item xs={3}>
					<Tooltip title={item.name} placement={"left"}>
						<Box
							sx={{
								...commonStyle,
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxWidth: "50rem",
								whiteSpace: "nowrap",
							}}
						>
							{item.name}
						</Box>
					</Tooltip>
				</Grid>
				<Grid item xs={2}>
					<Box sx={commonStyle}>{t(displayEmissionType(item))}</Box>
				</Grid>
				<Grid item xs={2}>
					<Box sx={commonStyle}>
						{DateTime.fromISO(item.createdDate).toFormat("yyyy-MM-dd HH:mm")}
					</Box>
				</Grid>
				<Grid item xs={2}>
					<Box sx={commonStyle}>
						{t("emissions.column.rest-capital-not-available")}
					</Box>
				</Grid>
				<Grid item xs={2}>
					<Box sx={commonStyle}>
						<Chip
							size={"small"}
							sx={{
								width: 120,
								backgroundColor: getDisplayStatusColor(item.status)[0],
								color: getDisplayStatusColor(item.status)[1],
							}}
							label={t(getDisplayStatus(item.status))}
						/>
					</Box>
				</Grid>
				<Grid item xs={1}>
					<Box sx={{ ...commonStyle, pr: 0, pl: 0 }} justifyContent="flex-end">
						{(emissionCanBeCancelled(item) ||
							emissionCanBePermanentlyCancelled(item)) && (
							<EmissionContextMenu emission={item} />
						)}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

const EmissionContextMenu = ({ emission }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
	const [permanentlyCancelDialogOpen, setPermanentlyCancelDialogOpen] =
		useState(false);
	const [setNotifyInviteesDialogOpen] = useState(false);
	const dispatch = useDispatch();
	const open = !!anchorEl;
	const { t } = useTranslation();

	const handleClose = (e) => {
		e.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<>
			<CancelEmissionDialog
				emission={emission}
				open={cancelDialogOpen}
				onClose={(e) => {
					handleClose(e);
					setCancelDialogOpen(false);
				}}
				onAccept={async (e) => {
					await dispatch(cancelEmission(emission.companyId, emission.id));
					handleClose(e);
					setCancelDialogOpen(false);
				}}
				showNotificationDialog={() => {
					setCancelDialogOpen(false);
					setNotifyInviteesDialogOpen(false);
				}}
			/>
			<PermanentlyCancelEmissionDialog
				emission={emission}
				open={permanentlyCancelDialogOpen}
				onClose={(e) => {
					e.stopPropagation();
					handleClose(e);
					setPermanentlyCancelDialogOpen(false);
				}}
				onAccept={async (e) => {
					e.stopPropagation();
					await dispatch(
						permanentlyCancelEmission(emission.companyId, emission.id),
					);
					handleClose(e);
					setPermanentlyCancelDialogOpen(false);
				}}
			/>
			<IconButton
				sx={{
					ml: 3,
				}}
				onClick={(e) => {
					e.stopPropagation();
					setAnchorEl(e.currentTarget);
				}}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClick={(e) => e.stopPropagation()}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				{emissionCanBeCancelled(emission) ? (
					<MenuItem
						onClick={(e) => {
							e.stopPropagation();
							setCancelDialogOpen(true);
							setAnchorEl(null);
						}}
					>
						{t("emissions.cancellation")}
					</MenuItem>
				) : (
					""
				)}
				{emissionCanBePermanentlyCancelled(emission) ? (
					<MenuItem
						onClick={(e) => {
							e.stopPropagation();
							setPermanentlyCancelDialogOpen(true);
							setAnchorEl(null);
						}}
					>
						{t("emissions.cancellation.cancel-permanently")}
					</MenuItem>
				) : (
					""
				)}
			</Menu>
		</>
	);
};

const CancelEmissionDialog = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const {
		common: { emissionStatus },
	} = emissionConsts;
	const language = useSelector((state) => state.i18n.language);

	const [showNotificationMailForm, setShowNotificationMailForm] =
		useState(false);
	const [template, setTemplate] = useState("");
	const [message, setMessage] = useState("");
	const [subject, setSubject] = useState("");

	const subjectFieldRef = useRef();

	useEffect(() => {
		if (showNotificationMailForm) {
			const f = async () => {
				const response = await emissions.getInformTemplate(
					props.emission.companyId,
					props.emission.id,
					emissionConsts.templates.cancellationInformation,
					language,
				);

				setTemplate(response.template);
				setMessage(response.template);
				subjectFieldRef.current.focus();
			};
			f();
		}
	}, [showNotificationMailForm]);

	async function validate(values) {
		const errors = {};
		if (values.subject === "") {
			errors.subject = t(
				"emissions.cancellation.cancel-and-inform-invitees.no-subject",
			);
		}
		if (values.message === "") {
			errors.message = t(
				"emissions.cancellation.cancel-and-inform-invitees.no-message",
			);
		}

		return errors;
	}

	const handleMessageChange = (event) => {
		setMessage(event);
	};
	const handleSubjectChange = (event) => {
		setSubject(event.target.value);
	};
	const handleSubmit = async (e) => {
		subjectFieldRef.current.focus();
		subjectFieldRef.current.blur();

		const errors = await validate({ subject, message });

		if (Object.keys(errors).length === 0) {
			await dispatch(
				sendEmissionCancellationEmail(
					props.emission.companyId,
					props.emission.id,
					subject,
					message,
				),
			);
			await dispatch(
				cancelEmission(props.emission.companyId, props.emission.id),
			);
			setShowNotificationMailForm(false);
			props.onClose(e);
		}
	};

	return (
		<Dialog
			maxWidth={"md"}
			fullWidth={true}
			open={props.open}
			onClose={(e) => {
				props.onClose(e);
				setShowNotificationMailForm(false);
			}}
		>
			<Box sx={{ padding: 4 }} onClick={(e) => e.stopPropagation()}>
				<DialogTitle>
					{showNotificationMailForm
						? t("emissions.cancellation.cancel-and-inform-invitees")
						: t("emissions.cancellation")}
				</DialogTitle>
				<DialogContent sx={{ pt: 4 }}>
					{showNotificationMailForm ? (
						<Form
							keepDirtyOnReinitialize={true}
							subscription={{
								valid: true,
							}}
							initialValues={{ subject, message }}
							validate={validate}
							onSubmit={handleSubmit}
						>
							{({ handleSubmit }) => {
								return (
									<form onSubmit={handleSubmit}>
										<DialogContentText>
											{t(
												"emissions.cancellation.cancel-and-inform-invitees-text",
											)}
										</DialogContentText>
										<TextField
											inputRef={subjectFieldRef}
											name={"subject"}
											label={t("subject")}
											sx={{ mt: "12px", mb: "20px" }}
											value={subject}
											onChange={handleSubjectChange}
										/>
										<RichTextEditor
											InputProps={{
												sx: { minHeight: "148px", alignItems: "flex-start" },
											}}
											name={"message"}
											label={t("message")}
											value={message !== "" ? message : template}
											onChange={handleMessageChange}
										/>
									</form>
								);
							}}
						</Form>
					) : props.emission.status === emissionStatus.invitationsSent ? (
						t(
							"emissions.cancellation.cancel-emission-warning.invitees-will-be-informed",
							{
								name: props.emission.name,
							},
						)
					) : (
						t("emissions.cancellation.cancel-emission-warning", {
							name: props.emission.name,
						})
					)}
				</DialogContent>
				<DialogActions>
					{showNotificationMailForm ? (
						<Button
							variant={"contained"}
							color={"warning"}
							onClick={handleSubmit}
						>
							{t(
								"emissions.cancellation.cancel-emission-warning.inform-and-cancel",
							)}
						</Button>
					) : (
						<Button
							variant={"contained"}
							color={"warning"}
							onClick={(e) => {
								if (props.emission.status === emissionStatus.invitationsSent) {
									setShowNotificationMailForm(true);
								} else {
									props.onAccept(e);
								}
							}}
						>
							{t("emissions.cancellation.yes-action")}
						</Button>
					)}
					<Button
						variant={"contained"}
						onClick={(e) => {
							setShowNotificationMailForm(false);
							props.onClose(e);
						}}
					>
						{t("emissions.cancellation.no-action")}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

const PermanentlyCancelEmissionDialog = (props) => {
	const { t } = useTranslation();

	return (
		<Dialog open={props.open} onClose={(e) => props.onClose(e)}>
			<Box onClick={(e) => e.stopPropagation()}>
				<DialogTitle>
					{t("emissions.cancellation.cancel-permanently")}?
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t("emissions.permanent-cancellation.cancel-emission-warning", {
							name: props.emission.name,
						})}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant={"contained"}
						color={"warning"}
						onClick={(e) => {
							props.onAccept(e);
						}}
					>
						{t("emissions.cancellation.yes-action")}
					</Button>
					<Button variant={"contained"} onClick={(e) => props.onClose(e)}>
						{t("emissions.cancellation.no-action")}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default EmissionsForAdmin;
