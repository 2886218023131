import React from "react";
import PropTypes from "prop-types";
import DialogModal from "@/components/dialogModal";
import Alert from "@/components/alert";
import ControlSection from "@/components/controlSection";
import PaymentMethods from "./paymentMethods";
import { useTranslation } from "react-i18next";
import useDefaultPaymentMethod from "@/hooks/useDefaultPaymentMethod";
import { CreditCard } from "lucide-react";
import useCompanyIdFromUrl from "@/hooks/useCompanyIdFromUrl";
import LoadingPanel from "../loadingPanel";

const SubscriptionCheckoutModal = ({
	onCancel,
	onSubmit,
	confirmationMessageRenderer,
	...props
}) => {
	const { t } = useTranslation();
	const companyId = useCompanyIdFromUrl();

	const {
		isLoading: isLoadingDefaultPaymentMethod,
		data: defaultPaymentMethod,
	} = useDefaultPaymentMethod();

	if (!companyId || isLoadingDefaultPaymentMethod) return <LoadingPanel />;

	const confirmationMessage = confirmationMessageRenderer?.();

	return (
		<DialogModal
			size="sm"
			title={t("subscriptions.purchase.change_subscription")}
			submitText={
				<>
					<CreditCard size={16} />
					{t("subscriptions.checkout.confirm_payment")}
				</>
			}
			onCancel={onCancel}
			onSubmit={onSubmit}
			submitDisabled={!defaultPaymentMethod}
			bodyRenderer={() => (
				<div className="w-full flex gap-6 flex-col">
					{!!confirmationMessage?.length && (
						<Alert
							icon={<CreditCard />}
							title={t("generic.please_note")}
							message={confirmationMessage}
						/>
					)}
					{!defaultPaymentMethod && (
						<Alert
							title={t(
								"subscriptions.payment_methods.payment_method_required.title",
							)}
							message={t(
								"subscriptions.payment_methods.payment_method_required.message",
							)}
							variant="destructive"
						/>
					)}
					<ControlSection
						title={t("subscriptions.payment_methods.singular")}
						variant={!defaultPaymentMethod ? "destructive" : undefined}
					>
						<PaymentMethods companyId={companyId} autoOpenAddPaymentMethod />
					</ControlSection>
				</div>
			)}
			{...props}
		/>
	);
};

SubscriptionCheckoutModal.propTypes = {
	onCancel: DialogModal.propTypes.onCancel,
	onSubmit: DialogModal.propTypes.onSubmit,
	confirmationMessageRenderer: PropTypes.func,
};

export default SubscriptionCheckoutModal;
