import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";

import { cn } from "@/components/utils";
import { bool, string } from "prop-types";

const Checkbox = React.forwardRef(
	({ className, error, label, id, ...props }, ref) => {
		const renderError = (error) =>
			error && <p className="text-destructive text-sm">{error}</p>;

		return (
			<div>
				<div className="flex gap-4 items-center">
					<CheckboxPrimitive.Root
						ref={ref}
						className={cn(
							"peer h-8 w-8 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
							{ "border-destructive": error },
							className,
						)}
						id={id}
						{...props}
					>
						<CheckboxPrimitive.Indicator
							className={cn("flex items-center justify-center text-current")}
						>
							<Check className="h-6 w-6" />
						</CheckboxPrimitive.Indicator>
					</CheckboxPrimitive.Root>
					{label && (
						<label htmlFor={id} className="m-0">
							{label}
						</label>
					)}
				</div>
				{Array.isArray(error)
					? error.map((err) => renderError(err))
					: renderError(error)}
			</div>
		);
	},
);

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

Checkbox.propTypes = {
	className: string,
	error: bool,
};

export { Checkbox };
