import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";
import { fromJS, Map, List } from "immutable";
import debounce from "lodash/debounce";
import ScrollView from "../../../../dumb-components/shared/layout/scroll-view/scroll-view";
import {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	cancelTemporaryTransaction,
} from "../../../../actions/transaction.actions";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../../../actions/notify.actions";
import DatePicker from "../../../../dumb-components/shared/datepicker/datepicker";
import Panel from "../../../../dumb-components/panel";
import Field from "../../../../dumb-components/fields/field";
import Select from "../../../../dumb-components/fields/select";
import ToggleSwitch from "../../../../dumb-components/shared/toggle-switch/toggle-switch";
import NumericInput from "../../../../dumb-components/fields/numeric-input";
import {
	validateTransactionDate,
	isRequired,
	validateShareTypeFrom,
	validateShareTypeTo,
	validateisRequiredImmutable,
	validateNotEmptyInvestorsShares,
	validateZeroSharesToConvert,
	validateNumOfSharesToConvertLtLimit,
} from "../../../../modules/validation.module";
import immutableForm from "../../../framework/immutable-form";
import { getFullShareTypeLabel } from "../../../helpers/shares";
import TransactionToolbarControlsContainer from "../../../../containers/shares/transaction-toolbar-controls.container";
import { openModal } from "../../../../actions/modals.actions";
import { SHARES_SHARE_TYPE_MODAL } from "../../../../constants/modals";

const transactionType = "CONVERSION-OF-SHARES";
const initialTransaction = fromJS({
	type: transactionType,
	handlerData: {},
});

const de = debounce((func, type, transaction) => {
	func(type, transaction.toJS());
}, 1000);

class ConversionOfSharesForm extends Component {
	state = {
		shareTypes: List(),
		expandedRows: Map(),
	};

	componentDidMount = () => {
		const { fetchTemporaryTransaction, transaction, tmpTransaction } =
			this.props;

		fetchTemporaryTransaction(transactionType);
		this.setShareTypes(transaction);
		this.setValidators(tmpTransaction);
	};

	componentDidUpdate = (prevProps) => {
		const newShareType = this.props.tmpTransaction
			? this.props.tmpTransaction.getIn(["handlerData", "newShareType"])
			: null;

		if (this.props.transaction !== prevProps.transaction) {
			this.setShareTypes(this.props.transaction);
		}

		if (newShareType) {
			this.appendNewShareTypeToShareTypesList(newShareType);
		}

		if (this.props.tmpTransaction) {
			this.setValidators(this.props.tmpTransaction);
		}
	};

	setShareTypes = (transaction) => {
		const { tmpTransaction } = this.props;
		if (!transaction) {
			return;
		}

		const shareTypes = transaction.getIn(["shareData", "types"]);

		if (!shareTypes) {
			return;
		}

		let shareTypesOptions = List();
		shareTypes.forEach((obj) => {
			shareTypesOptions = shareTypesOptions.push(
				Map({
					value: obj.get("type"),
					label: getFullShareTypeLabel(obj.get("type")),
				}),
			);
		});

		const tmpShareType = tmpTransaction.getIn(["handlerData", "newShareType"]);

		const alreadyExist =
			tmpShareType &&
			shareTypes.some((shareType) => {
				return shareType.get("type") === tmpShareType.get("type");
			});

		if (tmpShareType && !alreadyExist) {
			shareTypesOptions = shareTypesOptions.push(
				Map({
					value: tmpShareType.get("type"),
					label: getFullShareTypeLabel(tmpShareType.get("type")),
				}),
			);
		}

		this.setState({ shareTypes: shareTypesOptions });
	};

	setValidators = (tmpTransaction) => {
		const { disableValidationOfField, enableValidationOfField } = this.props;

		if (tmpTransaction.getIn(["handlerData", "shareTypeFrom"])) {
			enableValidationOfField("handlerData.investors");
			return;
		}

		// Default validators
		disableValidationOfField("handlerData.investors");
	};

	appendNewShareTypeToShareTypesList = (newShareType) => {
		const newShareTypeType = newShareType.get("type");

		if (newShareTypeType) {
			return;
		}

		const optionObj = Map({
			value: newShareTypeType,
			label: getFullShareTypeLabel(newShareTypeType),
		});

		this.setState((prevState) => {
			return { ...prevState, shareTypes: prevState.shareTypes.push(optionObj) };
		});
	};

	onChange = (field, val) => {
		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		const newTransaction = tmpTransaction.setIn(["handlerData", field], val);

		resetErrors(field);
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeDate = (val) => {
		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		const newTransaction = tmpTransaction.set("date", val);

		resetErrors("date");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeShareType = (field, val) => {
		if (val === "__createNew") {
			this.createShareType();
			return;
		}

		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		let newTransaction = tmpTransaction.setIn(
			["handlerData", "investors"],
			Map(),
		);
		newTransaction = newTransaction.setIn(["handlerData", field], val);

		if (tmpTransaction.getIn(["handlerData", "type"]) === "all") {
			newTransaction = newTransaction.setIn(["handlerData", "type"], null);
		}

		resetErrors("handlerData." + field);
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeSequence = (investmentId, sequenceIndex, field, val) => {
		const { tmpTransaction } = this.props;
		let investors = tmpTransaction.getIn(["handlerData", "investors"], Map());
		let investor = investors.get(investmentId, List());
		const index = investor.findIndex((obj) => {
			return obj.get("index") === sequenceIndex;
		});

		if (index >= 0 && !val) {
			investor = investor.remove(index);
		} else if (index >= 0) {
			investor = investor.setIn([index, field], val);
		} else {
			let obj = Map({
				index: sequenceIndex,
				amount: 0,
			});
			obj = obj.set(field, val);
			investor = investor.push(obj);
		}

		investors = investors.set(investmentId, investor);
		this.onChange("investors", investors);
	};

	onToggleType = (val) => {
		const {
			tmpTransaction,
			transaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		let investors = Map();

		this.onChange("type", val ? "all" : "individual");

		if (!val) {
			investors = Map();
		} else {
			const sequences = transaction.get("sequences");
			const shareTypeFrom = tmpTransaction.getIn([
				"handlerData",
				"shareTypeFrom",
			]);

			sequences.forEach((sequence, index) => {
				if (sequence.get("type") === shareTypeFrom) {
					let investor = investors.has(sequence.get("investmentId"))
						? investors.get(sequence.get("investmentId"))
						: List();
					investor = investor.push(
						Map({
							index,
							amount:
								sequence.get("sequenceTo") - sequence.get("sequenceFrom") + 1,
						}),
					);
					investors = investors.set(sequence.get("investmentId"), investor);
				}
			});
		}

		let newTransaction = tmpTransaction.setIn(
			["handlerData", "investors"],
			investors,
		);
		newTransaction = newTransaction.setIn(
			["handlerData", "type"],
			val ? "all" : "individual",
		);

		resetErrors("handlerData.investors");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onSubmit = () => {
		const { tmpTransaction, createTransaction, validate } = this.props;

		if (validate(tmpTransaction)) {
			createTransaction(tmpTransaction.toJS());
		}
	};

	cancelTransaction = () => {
		const { cancelTemporaryTransaction, tmpTransaction } = this.props;
		let { shareTypes } = this.state;

		if (tmpTransaction.hasIn(["handlerData", "newShareType"])) {
			shareTypes = shareTypes.filter(
				(shareType) =>
					shareType.get("value") !==
					tmpTransaction.getIn(["handlerData", "newShareType", "type"]),
			);
			this.setState({ shareTypes });
		}

		cancelTemporaryTransaction(transactionType);
	};

	formatNumerInput = (num) => {
		const { formatNumber } = this.props.intl;

		if (isNaN(num)) {
			return num;
		}
		return formatNumber(num);
	};

	disableSelectedShareType = (shareTypes, valueToDisable) => {
		return shareTypes.map((shareType) => {
			if (shareType.get("value") === valueToDisable) {
				shareType = shareType.set("disabled", true);
			}

			return shareType;
		});
	};

	getDefaultTransactionLimitations = (sequence) => {
		const { tmpTransaction, transaction } = this.props;

		if (sequence.has("transactionLimitations")) {
			const tl = sequence.get("transactionLimitations", List());
			return tl.join ? tl.join(",") : tl;
		}

		const shareType = tmpTransaction.has("newShareType")
			? tmpTransaction.get("newShareType", List())
			: transaction.getIn(["shareData", "types"], List()).find((obj) => {
					return (
						obj.get("type") ===
						tmpTransaction.getIn(["handlerData", "shareTypeTo"])
					);
			  });

		if (!shareType) {
			return "";
		}

		const transactionLimitations = shareType.get(
			"transactionLimitations",
			List(),
		);
		return transactionLimitations.join
			? transactionLimitations.join(",")
			: transactionLimitations;
	};

	setExistingShareTypes = () => {
		const { transaction } = this.props;

		if (!transaction) {
			return;
		}

		const shareTypes = transaction.getIn(["shareData", "types"]);

		if (!shareTypes) {
			return;
		}

		let shareTypesOptions = List();
		shareTypes.forEach((obj) => {
			shareTypesOptions = shareTypesOptions.push(
				Map({
					value: obj.get("type"),
					label: getFullShareTypeLabel(obj.get("type")),
				}),
			);
		});

		return shareTypesOptions;
	};

	createShareType = () => {
		this.showModal(Map());
	};

	showModal = (shareType, index) => {
		const { transaction, openModal } = this.props;
		const quotaValue = transaction.getIn(["shareData", "quotaValue"]);

		shareType = shareType.set("numOfShares", 0);
		shareType = shareType.set("pricePerShare", quotaValue);
		shareType = shareType.set("shareClass", "ordinary%20share");
		shareType = shareType.set("type", "ordinary%20share$");

		openModal(SHARES_SHARE_TYPE_MODAL, {
			disableDeleteButton: true,
			numOfSharesReadonly: true,
			pricePerShareReadonly: true,
			selectableShareTypes: this.state.shareTypes.toJS(),
			shareType: shareType.toJS(),
			onSubmit: this.onSubmitModalForm.bind(this, index),
		});
	};

	onSubmitModalForm = (index, shareType) => {
		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		let shareTypes = this.state.shareTypes;
		shareType = fromJS(shareType); // Remove when modal is using immutable

		shareTypes = shareTypes.push(
			fromJS({
				value: shareType.get("type"),
				label: getFullShareTypeLabel(shareType.get("type")),
			}),
		);

		let newTransaction = tmpTransaction.setIn(
			["handlerData", "shareTypeTo"],
			shareType.get("type"),
		);
		newTransaction = newTransaction.setIn(
			["handlerData", "newShareType"],
			shareType,
		);
		newTransaction = newTransaction.setIn(["handlerData", "investors"], Map());

		this.setState({ shareTypes });
		resetErrors("handlerData.shareTypeTo");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	renderButtons = () => {
		return (
			<div className="i-content__tabs i-content__tabs--fix-padding">
				<TransactionToolbarControlsContainer
					onSubmit={this.onSubmit}
					onCancel={this.cancelTransaction}
				/>
			</div>
		);
	};

	renderSequence = (sequence, index) => {
		const { tmpTransaction } = this.props;

		const seqFrom = sequence.get("sequenceFrom");
		const seqTo = sequence.get("sequenceTo");
		const shares = seqTo - seqFrom + 1;
		const investorId = sequence.get("investmentId");
		const inputSequence =
			tmpTransaction
				.getIn(["handlerData", "investors", investorId], List())
				.find((obj) => {
					return obj.get("index") === sequence.get("sequenceIndex");
				}) || Map();
		const conversionTypeIsAll =
			tmpTransaction.getIn(["handlerData", "type"]) === "all" ? true : false;

		return (
			<div key={index}>
				<div className="list__item">
					<div className="list__item__body">
						<span className="text--align-left">
							{seqFrom} - {seqTo} ({getFullShareTypeLabel(sequence.get("type"))}
							)
						</span>
					</div>
					<div className="ist__item__text-area list__item__text-area--x2">
						<span className="list__item__input list__item__input--pad-right text--align-right">
							{shares}
						</span>
					</div>
					<div className="list__item__text-area list__item__text-area--x3">
						<div className="list__item__input list__item__input--pad-right text--align-right">
							<div className="col-md-6 pull-right pad-hor-0">
								<NumericInput
									type="text"
									className="form-control text--align-right"
									value={inputSequence.get("amount")}
									onChange={(val) => {
										this.onChangeSequence(
											investorId,
											sequence.get("sequenceIndex"),
											"amount",
											val,
										);
									}}
									disabled={conversionTypeIsAll}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	renderInvestor = (balance, investmentId) => {
		const { investors, transaction, tmpTransaction } = this.props;

		if (!investors) {
			return null;
		}

		const investor = investors.find((inv) => {
			return inv.get("id") === investmentId;
		});

		if (!investor) {
			return null;
		}

		let sequences = List();
		balance.get("sequenceIndexs").forEach((index) => {
			let transactionSequence = transaction.getIn(["sequences", index]);

			if (
				transactionSequence.get("type") ===
				tmpTransaction.getIn(["handlerData", "shareTypeFrom"])
			) {
				transactionSequence = transactionSequence.set("sequenceIndex", index);
				sequences = sequences.push(transactionSequence);
			}
		});

		return (
			<div className={`i-panel`} key={investmentId}>
				<div className="i-panel__heading">
					<h2 className="i-panel__title">
						{investor.getIn(["investorInformation", "name"])}{" "}
						<span className="text--muted">
							{investor.getIn(["investorInformation", "id"])}
						</span>
					</h2>
				</div>
				<div className={`i-panel__body`}>
					<div className="form-group col-md-12">
						<div className="list__list-header">
							<div className="list__list-header__body list__list-header__text-area--pad-lft list__list-header__text-area--pad-right">
								<FormattedMessage id="shares.transactions.conversion_of_shares.sequence" />
							</div>
							<div className="list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right">
								<FormattedMessage id="shares.transactions.conersion_of_shares.number_of_shares" />
							</div>
							<div className="list__list-header__text-area list__list-header__text-area--x3 list__list-header__text-area--pad-right list__list-header__text-area--right">
								<FormattedMessage id="shares.transactions.onversion_of_shares.shares_to_convert" />
							</div>
						</div>
						<div className="list list--striped list--table">
							{sequences.map(this.renderSequence).toList()}
						</div>
					</div>
				</div>
			</div>
		);
	};

	renderInvestors = () => {
		const { transaction, tmpTransaction, errors } = this.props;
		const shareTypeFrom = tmpTransaction.getIn([
			"handlerData",
			"shareTypeFrom",
		]);
		const shareTypeTo = tmpTransaction.getIn(["handlerData", "shareTypeTo"]);
		const hasError = errors
			? errors.get("handlerData.investors", List()).size > 0
			: false;

		if (!transaction) {
			return null;
		}

		// Don't render if shareTypeFrom or shareTypeTo haven't been selected
		if (!shareTypeFrom || !shareTypeTo) {
			return null;
		}

		let balances = transaction.get("balances");

		if (!balances) {
			return null;
		}

		balances = balances.filter((obj) => {
			let found = false;

			obj.get("types").forEach((type) => {
				if (type.get("type") === shareTypeFrom && type.get("shares") > 0) {
					found = true;
				}
			});

			return found;
		});

		return (
			<div className={hasError ? "has-error" : ""}>
				{balances.map(this.renderInvestor).toList()}
			</div>
		);
	};

	render = () => {
		const { tmpTransaction, i18n, errors } = this.props;
		const { shareTypes } = this.state;

		if (!tmpTransaction) {
			return null;
		}

		const shareTypeFrom = tmpTransaction.getIn([
			"handlerData",
			"shareTypeFrom",
		]);
		const shareTypeTo = tmpTransaction.getIn(["handlerData", "shareTypeTo"]);

		let shareTypesOptionsForToField = shareTypes
			? this.disableSelectedShareType(shareTypes, shareTypeFrom)
			: List();
		shareTypesOptionsForToField = shareTypesOptionsForToField.push({
			value: "__createNew",
			label: i18n.messages["create_new_class_of_shares"],
		});

		const setExistingShareTypes = this.setExistingShareTypes();

		return (
			<div className="i-content__container">
				{this.renderButtons()}
				<ScrollView autoHide={true} showOnHover={true}>
					<Panel tid="conversion_of_shares">
						<Field
							name="date"
							errors={errors}
							tid="generic.date"
							className="col-md-5 col-md-offset-7"
						>
							<DatePicker
								hasError={errors ? errors.get("date", List()).size > 0 : false}
								calendarPlacement="bottom-end"
								value={tmpTransaction.get("date")}
								onChange={this.onChangeDate}
								language={i18n.language}
							/>
						</Field>

						<Field
							name="handlerData.shareTypeFrom"
							errors={errors}
							tid="shares.transactions.conversion_of_shares.from_class_of_shares"
						>
							<Select
								value={shareTypeFrom}
								options={
									setExistingShareTypes
										? this.disableSelectedShareType(
												setExistingShareTypes,
												shareTypeTo,
										  ).toJS()
										: null
								}
								simpleValue
								placeholder={i18n.messages["select_placeholder"]}
								onSelect={(val) => {
									this.onChangeShareType("shareTypeFrom", val);
								}}
							/>
						</Field>

						<Field
							name="handlerData.shareTypeTo"
							errors={errors}
							tid="shares.transactions.conversion_of_shares.to_class_of_shares"
						>
							<Select
								value={shareTypeTo}
								options={shareTypesOptionsForToField.toJS()}
								simpleValue
								placeholder={i18n.messages["select_placeholder"]}
								onSelect={(val) => {
									this.onChangeShareType("shareTypeTo", val);
								}}
							/>
						</Field>

						<Field>
							<FormattedHTMLMessage id="shares.transactions.conversion_of_shares.form.information" />
						</Field>

						<Field
							name="handlerData.byPercentage"
							errors={errors}
							className="col-md-4"
						>
							<ToggleSwitch
								checked={
									tmpTransaction.getIn(["handlerData", "type"]) === "all"
								}
								onChange={this.onToggleType}
							/>
						</Field>

						<Field className="form-group--mar-btm-0 col-md-12">
							<FormattedHTMLMessage id="shares.transactions.conversion_of_shares.form.information2" />
						</Field>
					</Panel>
					{this.renderInvestors()}
				</ScrollView>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get("transaction"),
		tmpTransaction: state.transaction.getIn(
			["tmpTransaction", transactionType],
			initialTransaction,
		),
		investors: state.investors.get("list"),
		i18n: state.i18n,
	};
}

const mapActionsToProps = {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	cancelTemporaryTransaction,
	addErrorNotification,
	addInfoNotification,
	openModal,
};

const validators = fromJS({
	date: {
		tid: "generic.date",
		rules: [
			{ func: isRequired, message: "validation.is_required" },
			{
				func: validateTransactionDate,
				message:
					"validation.current_transaction_date_must_be_later_then_last_transaction",
			},
		],
	},
	"handlerData.shareTypeFrom": {
		tid: "shares.transactions.conversion_of_shares.from_class_of_shares",
		rules: [
			{ func: isRequired, message: "validation.is_required" },
			{ func: validateShareTypeFrom, message: "validation.share_type_from" },
		],
	},
	"handlerData.shareTypeTo": {
		tid: "shares.transactions.conversion_of_shares.to_class_of_shares",
		rules: [
			{ func: isRequired, message: "validation.is_required" },
			{ func: validateShareTypeTo, message: "validation.share_type_to" },
		],
	},
	"handlerData.investors": {
		tid: "shares.transactions.conversion_of_shares.investors",
		rules: [
			{ func: validateisRequiredImmutable, message: "validation.is_required" },
			{
				func: validateNotEmptyInvestorsShares,
				message: "validation.is_required",
			},
			{
				func: validateZeroSharesToConvert,
				message: "validation.num_of_shares_to_convert_gt_0",
			},
			{
				func: validateNumOfSharesToConvertLtLimit,
				message:
					"validation.num_of_shares_to_convert_must_be_lighter_then_limit",
			},
		],
	},
});

const ConversionOfSharesFormImmutableForm = immutableForm(
	ConversionOfSharesForm,
	"conversionOfShares",
	validators,
);
const ConversionOfSharesFormInjectIntl = injectIntl(
	ConversionOfSharesFormImmutableForm,
);
export default connect(
	mapStateToProps,
	mapActionsToProps,
)(ConversionOfSharesFormInjectIntl);
