import React, { PureComponent } from "react";
import { func, bool, string } from "prop-types";
import styled from "styled-components";
import { getPublishWithEsignTooltipData } from "../../../components/helpers/meeting.helper";
import { map } from "react-immutable-proptypes";

import SimpleDarkInfoPanel from "../../shared/dark-info-panel/simple-dark-info-panel";
import { OutlinedButton } from "../../shared/button-v2";
import Text from "../../shared/text/text";
import Tooltip from "../../shared/tooltip/tooltip";
import Ul from "../../shared/ul/ul";

import {
	PROTOCOL_PUBLISHING_TYPE_ESIGNING,
	PROTOCOL_PUBLISHING_TYPE_WITHOUT_ESIGNING,
} from "/shared/constants";

const StyledSimpleDarkInfoPanel = styled(SimpleDarkInfoPanel)`
	flex-direction: column;
`;

const StyledButtonsWrapper = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.theme.spacing[3]};

	> span[data-tip="true"] {
		width: 100%;
	}
`;

const StyledBulletListItem = styled.li`
	color: ${(props) => props.theme.colors.white};
`;

const Li = ({ tid }) => (
	<StyledBulletListItem>
		<Text color="white" tid={tid} />
	</StyledBulletListItem>
);

export default class MeetingShareOrEsignProtocol extends PureComponent {
	static propTypes = {
		onChangeView: func,
		eSigningEnabled: bool,
		protocolMetadata: map,
		publishingType: string,
	};

	render = () => {
		const { onChangeView, eSigningEnabled, protocolMetadata, publishingType } =
			this.props;
		const { TOOLTIP_STATES, activeState, isDisabled } =
			getPublishWithEsignTooltipData({
				eSigningEnabled,
				protocolMetadata,
				publishingType,
			});

		return (
			<StyledSimpleDarkInfoPanel>
				<StyledButtonsWrapper>
					<Tooltip
						states={TOOLTIP_STATES}
						activeState={activeState}
						active={isDisabled}
					>
						<OutlinedButton
							icon="faSignature"
							tid="meetings.protocol.sign_or_publish.btn.publish_with_esign"
							onClick={onChangeView.bind(
								null,
								PROTOCOL_PUBLISHING_TYPE_ESIGNING,
							)}
							disabled={isDisabled}
							fullWidth
						/>
					</Tooltip>

					<OutlinedButton
						icon="faArrowAltFromBottom"
						tid="meetings.protocol.sign_or_publish.btn.publish_without_esign"
						onClick={onChangeView.bind(
							null,
							PROTOCOL_PUBLISHING_TYPE_WITHOUT_ESIGNING,
						)}
						fullWidth
						leftMargin
					/>
				</StyledButtonsWrapper>

				<Text color="white" tid="meetings.protocol.sign_or_publish.info_text" />
				<Ul>
					<Li tid="meetings.protocol.sign_or_publish.info_text.list.1" />
					<Li tid="meetings.protocol.sign_or_publish.info_text.list.2" />
					<Li tid="meetings.protocol.sign_or_publish.info_text.list.3" />
				</Ul>
			</StyledSimpleDarkInfoPanel>
		);
	};
}
