import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "../../../../interfaces/router";
import { isImmutable, Map } from "immutable";
import {
	createInvestor,
	listInvestors,
} from "../../../../actions/investors.actions";
import { formatIdPretty } from "../../../helpers/users";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import history from "../../../../interfaces/history";

import {
	EVENT_TYPE_SHARES_INVESTMENT_CREATE,
	EVENT_TYPE_SHARES_INVESTMENT_UPDATE,
	EVENT_TYPE_SHARES_INVESTMENT_DELETE,
	NUMBER_FORMAT_PERCENTAGE,
	NUMBER_FORMAT_INTEGER,
} from "/shared/constants";
import { Button } from "@/components/ui/button";
import i18n from "@/i18n";
import { Trans } from "react-i18next";

const { t } = i18n;

class InvestorsList extends Component {
	investorRefs = {};

	componentDidMount = () => {
		const { listInvestors } = this.props;
		listInvestors(false);
	};

	componentDidUpdate = () => {
		this.scrollToSelectedInvestor();
		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const { audit, listInvestors } = this.props;
		const investorCreate = audit.get(EVENT_TYPE_SHARES_INVESTMENT_CREATE);
		const investorUpdate = audit.get(EVENT_TYPE_SHARES_INVESTMENT_UPDATE);
		const investorDelete = audit.get(EVENT_TYPE_SHARES_INVESTMENT_DELETE);

		if (
			(investorCreate && investorCreate.get("refresh") === true) ||
			(investorUpdate && investorUpdate.get("refresh") === true) ||
			(investorDelete && investorDelete.get("refresh") === true)
		) {
			listInvestors();
		}
	};

	scrollToSelectedInvestor = () => {
		const { selectedInvestorId } = this.props;

		if (!selectedInvestorId) {
			return;
		}

		const htmlElement = this.investorRefs[selectedInvestorId];

		if (htmlElement && htmlElement.scrollIntoViewIfNeeded) {
			htmlElement.scrollIntoViewIfNeeded();
		}
	};

	setInvestorRef = (ref) => {
		if (!ref) {
			return;
		}

		this.investorRefs[ref.getAttribute("data-refid")] = ref;
	};

	renderInvestor = (investor) => {
		const { filterBy, onInvestorClick, i18n } = this.props;
		const showBy = (
			filterBy.find((obj) => obj.get("source") === "showBy") || Map()
		).getIn(["values", 0]);
		const id = investor.get("id");

		let itemIdentifier = investor.getIn(["investorInformation", "id"], "");
		const ownershipProp =
			showBy === "numOfShares" ? "numOfTotalShares" : "ownershipPercentage";
		const ownership = investor.getIn(["details", ownershipProp]);
		const name = investor.getIn(["investorInformation", "name"]);
		const toLink = this.props.basePath + "/" + id;

		itemIdentifier = formatIdPretty(itemIdentifier);

		if (investor.getIn(["investorTypeOfOwner"]) === "capitalInsurance") {
			itemIdentifier =
				i18n.messages["investments.capital_insurance.insurance_owner"] +
				": " +
				investor.getIn(["captialIncuranceOwnerInformation", "name"], "n/a");
		}

		const noShares = !ownership || ownership === 0;

		return (
			<NavLink
				to={toLink}
				onClick={onInvestorClick}
				activeClassName="list__item--active border-2 border-primary"
				className="list__item rounded-md border border-edge"
				key={id}
			>
				<div className="list__item__drag-area"></div>
				<div
					className={`list__item__total-shares ${
						noShares ? "list__item__total-shares--grey" : null
					}`}
				>
					{!noShares &&
						ownershipProp === "numOfTotalShares" &&
						localeFormatNumber(ownership, NUMBER_FORMAT_INTEGER)}
					{!noShares &&
						ownershipProp === "ownershipPercentage" &&
						localeFormatNumber(ownership, NUMBER_FORMAT_PERCENTAGE)}
				</div>
				<div
					className="list__item__body"
					data-refid={id}
					ref={this.setInvestorRef}
				>
					<span className="list__item__text list__item__text--strong">
						{name}
					</span>
					<span className="list__item__text">{itemIdentifier}</span>
				</div>
			</NavLink>
		);
	};

	splitInvestors = (investors) => {
		const active = [];
		const old = [];

		investors.forEach((investor) => {
			const numOfTotalShares = investor.getIn(["details", "numOfTotalShares"]);
			!numOfTotalShares || numOfTotalShares === 0
				? old.push(investor)
				: active.push(investor);
		});
		return [active, old];
	};

	/**
	 * The render function
	 */
	render() {
		const { investors, isLoading, createInvestor, basePath } = this.props;

		if (!isImmutable(investors)) {
			return null;
		}

		if (!isLoading && investors.size === 0) {
			return (
				<div className="flex flex-col h-full items-center gap-12 max-w-[800px] m-auto text-center">
					<div className="text-xl">
						{t("shares.shareholders.breadcrumbs.title")}
					</div>
					<Trans i18nKey="shareholders.zero_state.description" />
					<div className="flex gap-6">
						<Button
							onClick={() => {
								const { pathname } = window.location;
								const urlAlias = pathname.split("/")[1];
								history.push(`/${urlAlias}/shares/setup`);
							}}
						>
							{t("service.shares.setup")}
						</Button>
						<Button
							variant="secondary"
							onClick={() => {
								createInvestor({}, (err, investor) => {
									if (err) return;
									history.push(`${basePath}/${investor.get("id")}`);
								});
							}}
						>
							{t("add_new_shareholder")}
						</Button>
					</div>
				</div>
			);
		}

		return (
			<div className="list flex gap-2">
				{investors.map(this.renderInvestor)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		investors: state.investors.get("visibleInvestors"),
		isLoading: state.investors.get("isFetchingInvestors"),
		selectedInvestorId: state.investors.getIn(["selectedInvestor", "id"]),
		filterBy: state.investors.get("filterBy"),
		i18n: state.i18n,
		audit: state.audit.get("investors"),
	};
}

const mapActionsToProps = {
	listInvestors,
	createInvestor,
};

/** Export */
export default connect(mapStateToProps, mapActionsToProps)(InvestorsList);
