import React from "react";
import ProfileImage from "../../profile-image/profile-image";

const ProfileImageRenderer = ({ value, data }) => {
	return (
		<ProfileImage
			image={
				value
					? `/api/users/public/images/${value}-80x80?userId=${data.id}`
					: null
			}
		/>
	);
};

export { ProfileImageRenderer };
