import { useQuery } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";

export default (queryOptions = {}) => {
	return useQuery({
		queryKey: ["me"],
		staleTime: 1000 * 60 * 2, // 2 minutes,
		queryFn: () => {
			return apiClient.get(`users/me`);
		},
		...queryOptions,
	});
};
