import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fromJS, List } from "immutable";
import Accordion from "../../framework/accordion";
import AccordionSection from "../../framework/accordion/accordion-section";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";

import EmissionView from "./transactions/emission--view";
import RevertToLastTransaction from "./transactions/revert-to-last-transaction";
import TransferOfSharesView from "./transactions/transfer-of-shares--view";
import SplitView from "./transactions/split--view";
import BonusIssueView from "./transactions/bonus-issue--view";
import ChangeOfReservationView from "./transactions/change-of-reservation--view";
import AdjustmentOfVotesView from "./transactions/adjustment-of-votes--view";
import ConversionOfSharesView from "./transactions/conversion-of-shares--view";
import ReductionOfShareCapitalView from "./transactions/reduction-of-share-capital--view";
import SharesPledgeView from "./transactions/shares-pledge--view";
import IssueShareCertificatesView from "./transactions/issue-share-certificates--view";

import ShareTypeFormModal from "./transactions/share-type--form-modal";
import { SHARES_SHARE_TYPE_MODAL } from "../../../constants/modals";

import { Switch, Route } from "../../../interfaces/router";
import EmissionForm from "./transactions/emission--form";
import TransferOfSharesForm from "./transactions/transfer-of-shares--form";
import SplitForm from "./transactions/split--form";
import BonusIssueForm from "./transactions/bonus-issue--form";
import ChangeOfReservationForm from "./transactions/change-of-reservation--form";
import AdjustmentOfVotesForm from "./transactions/adjustment-of-votes--form";
import ConversionOfSharesForm from "./transactions/conversion-of-shares--form";
import ReductionOfShareCapitalForm from "./transactions/reduction-of-share-capital--form";
import SharesPledgeForm from "./transactions/shares-pledge--form";
import IssueShareCertificatesForm from "./transactions/issue-share-certificates--form";
import history from "../../../interfaces/history";
import { fetchLatestTransaction } from "../../../actions/transaction.actions";
import { listInvestors } from "../../../actions/investors.actions";

import BreadcrumbsContainer from "../../../containers/transactions/breadcrumbs.container";
import { anyActiveEmissions } from "../../../actions/emissions.actions";
import ConfirmContainer from "../../../containers/shared/confirm.container";

const allowedEmissionTransactions = [
	"transfer-of-shares",
	"shares-pledge",
	"issue-share-certificates",
];

const Transactions = ({ match }) => {
	const dispatch = useDispatch();
	const companyId = useSelector((state) => state.company.company.id);
	const i18n = useSelector((state) => state.i18n);
	const activeModal = useSelector((state) =>
		state.modals.getIn(["activeModal", "name"]),
	);
	const activeEmission = useSelector(
		(state) => state.emissions.anyActiveEmissions,
	);

	const [accordionSections, setAccordionSections] = useState(List());
	const [sectionsSorted, setSectionsSorted] = useState(false);
	const [selectedSection, setSelectedSection] = useState(null);
	const [selectedSectionId, setSelectedSectionId] = useState(null);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

	const scrollbarsRef = useRef(null);

	const useAccordionSections = useMemo(
		() =>
			fromJS([
				{
					title: "split_reverse_split",
					resource: "c69df621-157c-4bc1-97c0-98e253a19a8b",
					permissions: ["TRANSACTIONS--READ"],
					id: "split",
					component: SplitView,
				},
				{
					title: "new_emissions",
					resource: "c69df621-157c-4bc1-97c0-98e253a19a8b",
					permissions: ["TRANSACTIONS--READ"],
					id: "emission",
					component: EmissionView,
				},
				{
					title: "bonus_issues",
					resource: "c69df621-157c-4bc1-97c0-98e253a19a8b",
					permissions: ["TRANSACTIONS--READ"],
					id: "bonus-issue",
					component: BonusIssueView,
				},
				{
					title: "service.shares.transfer_of_shares_transaction",
					resource: "c69df621-157c-4bc1-97c0-98e253a19a8b",
					permissions: ["TRANSACTIONS--READ"],
					id: "transfer-of-shares",
					component: TransferOfSharesView,
				},
				{
					title: "conversion_of_shares",
					resource: "c69df621-157c-4bc1-97c0-98e253a19a8b",
					permissions: ["TRANSACTIONS--READ"],
					id: "conversion-of-shares",
					component: ConversionOfSharesView,
				},
				{
					title: "adjustment_of_votes",
					resource: "c69df621-157c-4bc1-97c0-98e253a19a8b",
					permissions: ["TRANSACTIONS--READ"],
					id: "adjustment-of-votes",
					component: AdjustmentOfVotesView,
				},
				{
					title: "reduction_of_share_capital",
					resource: "c69df621-157c-4bc1-97c0-98e253a19a8b",
					permissions: ["TRANSACTIONS--READ"],
					id: "reduction-of-share-capital",
					component: ReductionOfShareCapitalView,
				},
				{
					title: "change_of_reservation",
					resource: "c69df621-157c-4bc1-97c0-98e253a19a8b",
					permissions: ["TRANSACTIONS--READ"],
					id: "change-of-reservation",
					component: ChangeOfReservationView,
				},
				{
					title: "shares_pledge",
					resource: "c69df621-157c-4bc1-97c0-98e253a19a8b",
					permissions: ["TRANSACTIONS--READ"],
					id: "shares-pledge",
					component: SharesPledgeView,
				},
				{
					title: "issue_share_certificates",
					resource: "c69df621-157c-4bc1-97c0-98e253a19a8b",
					permissions: ["TRANSACTIONS--READ"],
					id: "issue-share-certificates",
					component: IssueShareCertificatesView,
				},
			]),
		[],
	);

	useEffect(() => {
		dispatch(fetchLatestTransaction());
		dispatch(listInvestors(false));
		sortAndFilterSections();

		dispatch(anyActiveEmissions(companyId)).then((hasAnyActiveEmission) => {
			if (hasAnyActiveEmission) {
				setIsConfirmModalOpen(true);
			}
		});
	}, [companyId]);

	useEffect(() => {
		if (accordionSections && accordionSections.size > 0) {
			doSetSelectedSection(window.location.pathname);
		}
	}, [window.location.pathname, accordionSections]);

	const doSetSelectedSection = (pathname) => {
		const sections = pathname.split("transactions");
		const section = sections.length === 2 ? sections[1].substr(1) : null;
		const selectedSection = accordionSections.findIndex((obj) => {
			return obj.get("id") === section;
		});
		setSelectedSection(selectedSection);
		setSelectedSectionId(section);
	};

	const toggleAccordionSection = useCallback(
		(section, scrollTo) => {
			const sectionToGoTo = selectedSectionId === section ? "" : section;

			history.push(
				window.location.pathname.split("transactions")[0] +
					`transactions/${sectionToGoTo}`,
			);
			setTimeout(() => {
				scrollbarsRef.current.scrollTop(scrollTo);
			}, 500);
		},
		[selectedSectionId],
	);

	const sortAndFilterSections = () => {
		let sections = useAccordionSections;
		if (sectionsSorted) {
			return;
		}
		sections = sections.sort((a, b) => {
			return i18n.messages[a.get("title")].localeCompare(
				i18n.messages[b.get("title")],
			);
		});

		if (activeEmission) {
			sections = sections.filter((section) => {
				return allowedEmissionTransactions.includes(section.get("id"));
			});
		}

		setAccordionSections(sections);
		setSectionsSorted(true);
	};

	const renderAccordionSection = (section, index) => {
		const ViewComponent = section.component;

		return (
			<AccordionSection
				title={section.title}
				resource={section.resource}
				permissions={section.permissions}
				onToggle={toggleAccordionSection.bind(this, section.id, index * 50)}
				id={`${section.id}-view`}
				key={index}
			>
				<ViewComponent />
			</AccordionSection>
		);
	};

	const renderAccordion = useMemo(() => {
		return (
			<ScrollView
				scrollbarRef={(r) => {
					scrollbarsRef.current = r;
				}}
				noLeftMargin
				noRightMargin
				showOnHover
				autoHide
			>
				<Accordion selectedSection={selectedSection}>
					{accordionSections.toJS().map(renderAccordionSection)}
				</Accordion>
			</ScrollView>
		);
	}, [accordionSections, selectedSection]);

	const { params } = match;

	if (accordionSections.toJS().length === 0) {
		return null;
	}

	return (
		<>
			<div className="flex flex-col h-full p-md">
				<BreadcrumbsContainer
					sections={accordionSections.toJS()}
					selectedSection={selectedSection}
					params={params}
				/>

				<div className="i-content__body">
					<div className="i-content__container">
						<div className="i-content__pre-scrollbar">
							<div className="i-content__container i-content__container--justify-center">
								<RevertToLastTransaction />
							</div>
						</div>
						<div className="f1-box">{renderAccordion}</div>
					</div>

					<div className="i-content__container">
						<Switch>
							<Route
								exact
								path={`${match.path}/emission`}
								component={EmissionForm}
							/>
							<Route
								exact
								path={`${match.path}/transfer-of-shares`}
								component={TransferOfSharesForm}
							/>
							<Route exact path={`${match.path}/split`} component={SplitForm} />
							<Route
								exact
								path={`${match.path}/bonus-issue`}
								component={BonusIssueForm}
							/>
							<Route
								exact
								path={`${match.path}/change-of-reservation`}
								component={ChangeOfReservationForm}
							/>
							<Route
								exact
								path={`${match.path}/adjustment-of-votes`}
								component={AdjustmentOfVotesForm}
							/>
							<Route
								exact
								path={`${match.path}/conversion-of-shares`}
								component={ConversionOfSharesForm}
							/>
							<Route
								exact
								path={`${match.path}/reduction-of-share-capital`}
								component={ReductionOfShareCapitalForm}
							/>
							<Route
								exact
								path={`${match.path}/shares-pledge`}
								component={SharesPledgeForm}
							/>
							<Route
								exact
								path={`${match.path}/issue-share-certificates`}
								component={IssueShareCertificatesForm}
							/>
						</Switch>
					</div>
				</div>

				<ShareTypeFormModal isOpen={activeModal === SHARES_SHARE_TYPE_MODAL} />
			</div>
			<ConfirmContainer
				title="transactions.modal.alert.emission_ongoing.title"
				message="transactions.modal.alert.emission_ongoing.message"
				onConfirm={() => {
					setIsConfirmModalOpen(false);
				}}
				isOpen={isConfirmModalOpen}
				mode={"alert"}
			></ConfirmContainer>
		</>
	);
};

export default Transactions;
