import { useEffect, useState } from "react";
import api from "@/modules/request.module.v2";

const useCreateStripeSetupIntent = () => {
	const [setupIntent, setSetupIntent] = useState(null);

	useEffect(() => {
		api.post("/subscriptions/setup-intent").then((response) => {
			setSetupIntent(response.data);
		});
	}, []);

	return setupIntent;
};
export default useCreateStripeSetupIntent;
