import React from "react";
import styled from "styled-components";
import { func, bool } from "prop-types";
import { ButtonTransparentIcon } from "../../shared/button";
import Tooltip from "../../shared/tooltip/tooltip";
import theme from "../../../../theme";

const StyledToolbarWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
`;

const StyledGreenIndicationDot = styled.div`
	position: absolute;
	top: 6px;
	left: 56px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: ${theme.colors.green};
`;

const BTN_ADD_SHAREHOLDER_STATES = {
	default: {
		tid: "tooltip.shares.actions.add_shareholder",
	},
	disabled: {
		tid: "tooltip.shares.emission.action.add_shareholder",
		delayShow: "instant",
	},
};

const TransactionToolbarControls = ({
	onSubmit,
	dropdownRenderer,
	onAddShareholder,
	addShareholderDisabled,
	showAddShareholderControl,
	sharesRemaining,
	isEmission,
	hasNewShares,
}) => {
	const renderNewShareholderControls = () => {
		if (!showAddShareholderControl) {
			return null;
		}

		return (
			<Tooltip
				states={BTN_ADD_SHAREHOLDER_STATES}
				activeState={addShareholderDisabled && "disabled"}
			>
				<ButtonTransparentIcon
					icon="faUserPlus"
					size="sml"
					onClick={onAddShareholder}
					disabled={addShareholderDisabled}
				/>
			</Tooltip>
		);
	};

	return (
		<StyledToolbarWrapper>
			{renderNewShareholderControls()}
			<Tooltip tid="tooltip.shares.action.accept_transaction">
				{isEmission && !sharesRemaining && hasNewShares && (
					<StyledGreenIndicationDot />
				)}
				<ButtonTransparentIcon icon="faRocket" size="sml" onClick={onSubmit} />
			</Tooltip>
			{dropdownRenderer && dropdownRenderer()}
		</StyledToolbarWrapper>
	);
};

TransactionToolbarControls.defaultProps = {
	addShareholderDisabled: false,
	showAddShareholderControl: false,
};

TransactionToolbarControls.propTypes = {
	onSubmit: func,
	dropdownRenderer: func,
	onAddShareholder: func,
	addShareholderDisabled: bool,
	showAddShareholderControl: bool,
};

export default TransactionToolbarControls;
