import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import { func, bool, arrayOf, shape, number } from "prop-types";
import Stepper from "../../../app/mui-components/stepper/stepper";

const PromotingStepsDialog = ({
	isOpen,
	steps,
	useStepper,
	onExited,
	onEntered,
	overrideIndex,
}) => {
	const [swipedOnce, setSwipedOnce] = useState(false);
	const [index, setIndex] = useState(overrideIndex || 0);
	const activeStep = index;
	const content = steps[index];

	const [slideIn, setSlideIn] = useState(true);
	const [slideDirection, setSlideDirection] = useState("left");

	const changeStep = (step) => {
		const direction = step > index ? "right" : "left";
		const newIndex = step;

		const oppositeDirection = direction === "left" ? "right" : "left";
		setSlideDirection(direction);
		setSlideIn(false);
		setSwipedOnce(true);

		setTimeout(() => {
			setIndex(newIndex);
			setSlideDirection(oppositeDirection);
			setSlideIn(true);
		}, 500);
	};

	const stepsLabels = steps.map((step) => ({ label: step.label }));

	return (
		<Dialog
			open={isOpen}
			maxWidth={"xl"}
			fullWidth={true}
			sx={{
				"&.MuiDialogContent-root": {
					padding: "unset!important",
				},
			}}
			PaperProps={{ sx: { height: "690px" } }}
		>
			<DialogContent sx={{ padding: "unset!important" }}>
				{useStepper && <Stepper steps={stepsLabels} activeStep={index} />}
				{swipedOnce ? (
					<Slide
						in={slideIn}
						direction={slideDirection}
						onExited={onExited && onExited.bind(null, activeStep)}
						onEntered={onEntered && onEntered.bind(null, activeStep)}
					>
						<Box sx={{ height: "100%" }}>
							{content?.slots?.body?.({ changeStep, activeStep })}
						</Box>
					</Slide>
				) : (
					<Box sx={{ height: "100%" }}>
						{content?.slots?.body?.({ changeStep, activeStep })}
					</Box>
				)}
			</DialogContent>
		</Dialog>
	);
};

PromotingStepsDialog.propTypes = {
	isOpen: bool,
	steps: arrayOf(
		shape({
			slots: shape({
				body: func.isRequired,
			}),
		}),
	),
	useStepper: bool,
	onExited: func,
	onEntered: func,
	overrideIndex: number,
};

PromotingStepsDialog.defaultProps = {
	modalSize: "lg",
};

export default PromotingStepsDialog;
