import React, { useState } from "react";
import { List, Map, fromJS, merge } from "immutable";
import moment from "../../modules/moment.module";
import { SUBSCRIPTION_ESIGN_BANKID_COST } from "/shared/constants";
import SubscriptionCheckoutModal from "../../components/subscriptions/checkoutModal";
import { Trans, useTranslation } from "react-i18next";

import PlansPanel from "../../dumb-components/subscriptions/plans-panel";

import {
	PRODUCT_ACCOUNT_PLAN_FREE_ID,
	PRODUCT_STORAGE_PLAN_FREE_ID,
	SUBSCRIPTIONS_INTERVAL_MONTH,
	SUBSCRIPTIONS_PLAN_TYPE_ACCOUNT,
	SUBSCRIPTIONS_PLAN_TYPE_STORAGE,
} from "/shared/constants";

import useQueryParams from "../../hooks/useQueryParams";
import useUpdateSubscriptionPlan from "../../hooks/useUpdateSubscriptionPlan";
import useUpdateSubscription from "@/hooks/useUpdateSubscription";
import useDialogModal from "@/hooks/useDialogModal";
import useCompanyFromUrl from "@/hooks/useCompanyFromUrl";

function PlansContainer(props) {
	const { t } = useTranslation();

	const { subscription, plans, documentsSpace, usedStorageSpace } = props;

	const currentAccountPlanId = subscription.accountPlanId;
	const currentStoragePlanId = subscription.storagePlanId;

	const { data: companyRequest } = useCompanyFromUrl();
	const company = companyRequest?.data;

	const companyDueForCancellation = company?.toBeCanceled;
	const companyId = company?.id;

	const [partnerCode, setPartnerCode] = useState(null);
	const [getQueryParam, setQueryParam] = useQueryParams();

	const isCheckingOut = getQueryParam("checkout") === "true";
	const setShowCheckout = (value) => setQueryParam("checkout", value);

	const { mutateAsync: updateSubscription } = useUpdateSubscription(companyId);

	const selectedAccountPlanId = getQueryParam("accountPlanId");
	const setSelectedAccountPlanId = (id) =>
		setQueryParam("accountPlanId", id === currentAccountPlanId ? null : id);

	const isFreeAccountPlanSelected =
		selectedAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID;

	const selectedStoragePlanId = isFreeAccountPlanSelected
		? PRODUCT_STORAGE_PLAN_FREE_ID
		: getQueryParam("storagePlanId");

	const setSelectedStoragePlanId = (id) =>
		setQueryParam("storagePlanId", id === currentStoragePlanId ? null : id);

	const {
		mutate: updateSubscriptionPlan,
		isPending: isUpdatingSubscriptionPlan,
	} = useUpdateSubscriptionPlan(companyId);

	const { confirm, alert, dialogElement } = useDialogModal();

	const convertAccountPlansToSelectOptions = (plansJS, freePlanId) => {
		const plans = fromJS(plansJS);
		let options = List();
		let optiongroups = List();
		const freePlan = plans.get("month").find((p) => p.get("id") === freePlanId);
		options = options.push(freePlan);

		plans.forEach((items, interval) => {
			const filteredItems = items
				.filter(
					(i) =>
						i.get("id") !== freePlanId &&
						i.getIn(["metadata", "active"]) !== "false",
				)
				.sort(sortByLevel);
			const optiongroup = Map({
				label: `subscriptions.plan_otions.optiongroup.${interval}`,
				options: List(filteredItems),
			});
			optiongroups = optiongroups.push(optiongroup);
		});

		optiongroups = optiongroups.sort((a, b) =>
			a.get("label").localeCompare(b.get("label")),
		);
		options = merge(options, optiongroups);

		return options;
	};

	const isDowngrading = () => {
		// No current subscription so we are not downgrading
		if (!currentAccountPlanId) {
			return false;
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false;
		}

		const currentAccountPlan = plans.mapPlanIdToPlan?.[currentAccountPlanId];
		const selectedAccountPlan = plans.mapPlanIdToPlan?.[selectedAccountPlanId];

		// We are downgrading if level of the current account plan is greater than the selected one
		if (
			parseInt(currentAccountPlan?.metadata?.level) >
			parseInt(selectedAccountPlan?.metadata?.level)
		) {
			return true;
		}

		return false;
	};

	const isDowngradingAllowed = () => {
		// No current subscription so accept
		if (!currentAccountPlanId) {
			return true;
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false;
		}

		const currentAccountPlan = plans.mapPlanIdToPlan?.[currentAccountPlanId];
		const selectedAccountPlan = plans.mapPlanIdToPlan?.[selectedAccountPlanId];

		let newStorageSpace;
		const currentStoragePlanId = subscription.storagePlanId;
		if (currentStoragePlanId) {
			const currentStoragePlan = plans.mapPlanIdToPlan?.[currentStoragePlanId];
			newStorageSpace =
				parseFloat(selectedAccountPlan?.metadata?.storage) +
				parseFloat(currentStoragePlan?.metadata?.storage);
		}

		if (selectedStoragePlanId) {
			const selectedStoragePlan =
				plans.mapPlanIdToPlan?.[selectedStoragePlanId];
			newStorageSpace =
				parseFloat(selectedAccountPlan?.metadata?.storage) +
				parseFloat(selectedStoragePlan?.metadata?.storage);
		}

		// We are downgrading if level of the current account plan is greater than the selected one
		// but the company have used too much storage space to allow downgrading.
		if (
			parseInt(currentAccountPlan?.metadata?.level) >
				parseInt(selectedAccountPlan?.metadata?.level) &&
			usedStorageSpace > newStorageSpace
		) {
			return false;
		}

		return true;
	};

	const showMessageAboutFolderSharing = () => {
		const { subscription, plans, isSharingFolders } = props;
		const currentAccountPlanId = subscription.accountPlanId;

		if (!currentAccountPlanId) {
			return false;
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false;
		}
		const currentAccountPlan = plans.mapPlanIdToPlan?.[currentAccountPlanId];
		const selectedAccountPlan = plans.mapPlanIdToPlan?.[selectedAccountPlanId];
		const folderSharingEnabledForCurrentAccountPlan =
			currentAccountPlan?.metadata?.folderSharingEnabled === "true";
		const folderSharingEnabledForSelectedAccountPlan =
			selectedAccountPlan?.metadata?.folderSharingEnabled === "true";

		if (
			folderSharingEnabledForCurrentAccountPlan &&
			!folderSharingEnabledForSelectedAccountPlan &&
			isSharingFolders
		) {
			return true;
		}

		return false;
	};

	const onSaveSubscription = () => {
		updateSubscription({ partnerCode });
	};

	const onChangeState = (field, val) => {
		if (field === "partnerCode") {
			setPartnerCode(val);
		}
	};

	const onSelectAccountPlan = (accountPlanIdValue) => {
		setSelectedAccountPlanId(accountPlanIdValue);
		if (accountPlanIdValue === PRODUCT_ACCOUNT_PLAN_FREE_ID) {
			setSelectedStoragePlanId(PRODUCT_STORAGE_PLAN_FREE_ID);
		}
	};

	const onSelectStoragePlan = (storagePlanIdValue) => {
		setSelectedStoragePlanId(storagePlanIdValue);
	};

	const onChangePlan = async () => {
		const { prepaidAmount } = props;

		const previousAccountPlanId = subscription.accountPlanId;
		const previousStoragePlanId = subscription.storagePlanId;

		const plansToChange = [];

		selectedAccountPlanId &&
			selectedAccountPlanId !== previousAccountPlanId &&
			plansToChange.push(selectedAccountPlanId);

		selectedStoragePlanId &&
			selectedStoragePlanId !== previousStoragePlanId &&
			plansToChange.push(selectedStoragePlanId);

		// If user upgraded from FREE account and don't have any money in the pot, show pot info modal
		const showPotInfo =
			plansToChange.includes(selectedAccountPlanId) &&
			previousAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID &&
			prepaidAmount <= 0;

		updateSubscriptionPlan(
			{ planIds: plansToChange },
			{
				onSuccess: () => {
					setShowCheckout(null);
					setSelectedAccountPlanId(null);
					setSelectedStoragePlanId(null);

					showPotInfo &&
						alert(
							t("subscriptions.balance_info.modal.title"),
							<Trans
								i18nKey="subscriptions.balance_info.modal.body"
								values={{
									singleSignaturePrice: `${SUBSCRIPTION_ESIGN_BANKID_COST} SEK`,
								}}
							/>,
							{ variant: "warning" },
						);
				},
			},
		);
	};

	const onClickPurchase = async () => {
		if (!isDowngrading()) return setShowCheckout(true);

		if (!isDowngradingAllowed()) {
			return await alert(
				t("subscriptions.alert_downgrading.title"),
				<Trans i18nKey="subscriptions.alert_downgrading.question" />,
				{ variant: "warning" },
			);
		}

		if (isFreeAccountPlanSelected) {
			return (
				(await confirm(
					t("subscriptions.button.cancel_subscription"),
					t("subscriptions.cancel_subscription_confirm.message"),
					{
						variant: "destructive",
						submitText: t("subscriptions.button.cancel_subscription"),
					},
				)) && onChangePlan()
			);
		}

		setShowCheckout(true);
	};

	const sortByLevel = (a, b) => {
		return (
			(parseInt(a?.metadata?.level) || 0) - (parseInt(b?.metadata?.level) || 0)
		);
	};

	if (!subscription || !plans) {
		return null;
	}

	const isFreeOfCharge = subscription?.isFreeOfCharge;

	const subscribedStoragePlanId =
		subscription.storagePlanId ?? PRODUCT_STORAGE_PLAN_FREE_ID;

	const subscribedStoragePlan =
		plans["mapPlanIdToPlan"]?.[subscribedStoragePlanId];
	const willChangeToStoragePlan = subscription?.willChangeToStoragePlan;

	const subscribedAccountPlanId =
		subscription?.accountPlanId ?? PRODUCT_ACCOUNT_PLAN_FREE_ID;

	const subscribedAccountPlan =
		plans["mapPlanIdToPlan"]?.[subscribedAccountPlanId];
	const willChangeToAccountPlan = subscription?.willChangeToAccountPlan;

	const selectedAccountPlan =
		selectedAccountPlanId && plans["mapPlanIdToPlan"]?.[selectedAccountPlanId];

	const stripeData = subscription.stripeData;

	const currentPeriodEnd =
		stripeData?.subscriptions?.data?.[0]?.current_period_end;

	const nextBillingDate =
		currentPeriodEnd && moment.unix(currentPeriodEnd).format("LL");

	//If you have a free account you should not be allowed to have a storage plan.
	const willHaveStoragePlan =
		(selectedStoragePlanId &&
			selectedStoragePlanId !== PRODUCT_STORAGE_PLAN_FREE_ID) ||
		(!selectedStoragePlanId &&
			subscribedStoragePlanId !== PRODUCT_STORAGE_PLAN_FREE_ID);
	const willHaveFreeAccountPlan =
		(selectedAccountPlanId &&
			selectedAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID) ||
		(!selectedAccountPlanId &&
			subscribedAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID);
	const isFreeWithStoragePlan = willHaveStoragePlan && willHaveFreeAccountPlan;

	let interval = SUBSCRIPTIONS_INTERVAL_MONTH;
	if (selectedAccountPlan && selectedAccountPlan.interval) {
		interval = selectedAccountPlan.interval;
	} else if (subscribedAccountPlan && subscribedAccountPlan.interval) {
		interval = subscribedAccountPlan.interval;
	}

	let accountPlans = plans[SUBSCRIPTIONS_PLAN_TYPE_ACCOUNT];
	let storagePlans = plans[SUBSCRIPTIONS_PLAN_TYPE_STORAGE]?.[interval];

	storagePlans = storagePlans
		.filter((p) => p?.metadata?.active === "true")
		.sort(sortByLevel);

	const currentPartnerCode = subscription.partnerCode;

	let mapSelectedTypeToInterval = {
		storage: {
			month: null,
			year: null,
		},
	};

	if (subscribedStoragePlan?.interval === "year") {
		mapSelectedTypeToInterval.storage.year = subscribedStoragePlan;
		mapSelectedTypeToInterval.storage.month = storagePlans.find(
			(plan) =>
				plan?.metadata?.level === subscribedStoragePlan?.metadata?.level &&
				plan?.interval !== subscribedStoragePlan?.interval,
		);
	} else {
		mapSelectedTypeToInterval.storage.month = subscribedStoragePlan;
		mapSelectedTypeToInterval.storage.year = storagePlans.find(
			(plan) =>
				plan?.metadata?.level === subscribedStoragePlan?.metadata?.level &&
				plan?.interval !== subscribedStoragePlan?.interval,
		);
	}

	const confirmPurchaseMessages = [
		!isFreeAccountPlanSelected && t("subscriptions.confirm_purchase.question"),
		showMessageAboutFolderSharing() &&
			t("subscriptions.confirm_purchase.question.sharing_folders"),
	].filter(Boolean);

	const _partnerCode = () => {
		if (partnerCode === "") {
			return "";
		}
		if (partnerCode) {
			return partnerCode;
		}

		if (currentPartnerCode) {
			return currentPartnerCode;
		}
	};

	return (
		<React.Fragment>
			<PlansPanel
				accountPlans={convertAccountPlansToSelectOptions(
					accountPlans,
					PRODUCT_ACCOUNT_PLAN_FREE_ID,
				)}
				storagePlans={fromJS(storagePlans)}
				selectedAccountPlanId={
					selectedAccountPlanId ||
					willChangeToAccountPlan ||
					subscribedAccountPlanId
				}
				selectedStoragePlanId={
					selectedStoragePlanId ||
					willChangeToStoragePlan ||
					mapSelectedTypeToInterval?.storage?.[interval]?.id
				}
				selectedAccountPlan={selectedAccountPlan}
				subscribedAccountPlan={subscribedAccountPlan}
				subscribedStoragePlan={subscribedStoragePlan}
				isLoading={isCheckingOut}
				nextBillingDate={nextBillingDate}
				isFreeOfCharge={isFreeOfCharge}
				disabled={
					isFreeOfCharge ||
					willChangeToStoragePlan ||
					willChangeToAccountPlan ||
					companyDueForCancellation
				}
				onSelectAccountPlan={onSelectAccountPlan}
				onSelectStoragePlan={onSelectStoragePlan}
				onClickPurchase={onClickPurchase}
				partnerCode={_partnerCode()}
				onChangeState={onChangeState}
				onSaveSubscription={onSaveSubscription}
				isFreeWithStoragePlan={isFreeWithStoragePlan}
				companyDueForCancellation={companyDueForCancellation}
				willHaveFreeAccountPlan={willHaveFreeAccountPlan}
				documentsSpace={documentsSpace}
				usedStorageSpace={usedStorageSpace}
			/>
			{isCheckingOut && (
				<SubscriptionCheckoutModal
					confirmationMessageRenderer={() =>
						confirmPurchaseMessages.map((message) => (
							<p key={message}>{message}</p>
						))
					}
					onCancel={() => setShowCheckout(null)}
					onSubmit={onChangePlan}
					isSubmitting={isUpdatingSubscriptionPlan}
				/>
			)}
			{dialogElement}
		</React.Fragment>
	);
}

export default PlansContainer;
