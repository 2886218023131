import React, { Component } from "react";
import { connect } from "react-redux";
import { List } from "immutable";

import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";

import RemindShareholdersModalContainer from "../notify-by-templates/modals/shares/remind-shareholders-modal.container";
import { openModal } from "../../actions/modals.actions";

import { REMIND_SHAREHOLDERS_EMAIL_TEMPLATE_MODAL } from "../../constants/modals";

class RemindShareholderAboutRegistrationDropdownItem extends Component {
	openModal = () => {
		this.props.openModal(REMIND_SHAREHOLDERS_EMAIL_TEMPLATE_MODAL);
	};

	getTooltipData = () => {
		const { visibleInvestors } = this.props;
		const everyShareholdersIsRegistered = !visibleInvestors.some(
			(s) => !s.get("investorId"),
		);

		if (everyShareholdersIsRegistered) {
			return {
				isDisabled: true,
				tooltipTid:
					"shareholders.toolbar.remind.tooltip.no_unregistered_shareholders",
			};
		}

		return {
			isDisabled: false,
			tooltipTid: "",
		};
	};

	render = () => {
		const { isDisabled, tooltipTid } = this.getTooltipData();

		return (
			<>
				<Tooltip tid={tooltipTid} delayShow="instant" active={isDisabled}>
					<DropdownIconItem
						icon="faRepeat"
						tid="shareholders.toolbar.remind.item.remind_shareholders"
						onClick={this.openModal}
						disabled={isDisabled}
					/>
				</Tooltip>

				<RemindShareholdersModalContainer />
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		visibleInvestors: store.investors.get("visibleInvestors", List()) || List(),
	};
};

const mapActionsToProps = {
	openModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(RemindShareholderAboutRegistrationDropdownItem);
