import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "../modules/request.module.v2";

export default (companyId) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["deletePaymentMethod"],
		mutationFn: (paymentMethodId) => {
			return apiClient.delete(
				`subscriptions/payment-methods/${paymentMethodId}`,
			);
		},
		onSuccess: (_response, paymentMethodId) => {
			queryClient.setQueryData(["paymentMethods", companyId], (prevState) => ({
				...prevState,
				data: prevState.data.filter(
					(paymentMethod) => paymentMethod.id !== paymentMethodId,
				),
			}));
		},
	});
};
