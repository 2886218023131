import { useQuery } from "@tanstack/react-query";
import apiClient from "../modules/request.module.v2";

const codeIsValid = (code) => {
	return code?.trim?.()?.length === 8;
};

export default (code) => {
	return useQuery({
		enabled: codeIsValid(code),
		queryKey: ["subscription", "coupon", code],
		queryFn: () => {
			return apiClient.get(`subscriptions/coupon/${code}`);
		},
		retry: false,
	});
};
