import React, { useState } from "react";
import { connect } from "react-redux";
import InvestorsList from "./investors/investors-list";

import { Margin } from "styled-components-spacing";
import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";

import Text from "../../../dumb-components/shared/text/text";
import Breadcrumbs from "../../../dumb-components/shared/breadcrumbs/breadcrumbs";
import BreadcrumbsItem from "../../../dumb-components/shared/breadcrumbs/breadcrumbs-item";

import ShareholdersToolbarControlsContainer from "../../../containers/shares/shareholders-toolbar-controls.container";
import ShareholdersAdvancedFilterContainer from "../../../containers/shares/shareholders-advanced-filter.container";
import ShareholdersAdvancedFilterBtnContainer from "../../../containers/shares/shareholders-advanced-filter-btn.container";
import ShareholdersFilterAndSortContainer from "../../../containers/shares/shareholders-filter-and-sort.container";
import ShareholderAddShareholderBtnContainer from "../../../containers/shares/shareholders-add-shareholder-btn.container";
import InvestorsDetails from "./investors/investors-details";
import Alert from "../../../dumb-components/shared/alert/alert";
import { List } from "immutable";
import { openModal } from "../../../actions/modals.actions";
import { COMPANY_INVESTMENTS_LIMITATIONS_MODAL } from "../../../constants/modals";
import { ShareholderLimitationInfoModal } from "../../../containers/shares/modals/shareholder-limitation-info-modal";
import useSubscriptionHelper from "../../../hooks/useSubscriptionHelper";

/** Class representing the Shares component */
/** Function representing the Shares component */
const Investors = ({
	location,
	selectedInvestor,
	visibleInvestors,
	history,
	numOfInvestors,
	numOfActiveShareholders,
	match,
	openModal,
}) => {
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [prevInvestor, setPrevInvestor] = useState(null);
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	const maxNumberOfShareholders = subscriptionHelper?.maxNumberOfShareholders;

	const getComponentBasePath = () => {
		const pathNameArray = location.pathname.split("/");

		if (
			pathNameArray[pathNameArray.length - 1] !== "register-of-shareholders"
		) {
			pathNameArray.pop();
		}

		return pathNameArray.join("/");
	};

	const toggleAdvancedFilter = () => {
		const basePath = getComponentBasePath();

		if (prevInvestor) {
			const id = prevInvestor.get("id");

			const prevVisible =
				visibleInvestors &&
				visibleInvestors.find((investor) => {
					return investor.get("id") === id;
				});

			if (prevVisible) {
				history.push(`${basePath}/${prevInvestor.get("id")}`);
			} else {
				history.push(basePath);
			}

			setPrevInvestor(null);
		} else {
			history.push(basePath);
			setPrevInvestor(selectedInvestor);
		}

		setFilterIsOpen(!filterIsOpen);
	};

	const closeAdvancedFilter = () => {
		setFilterIsOpen(false);
		setPrevInvestor(null);
	};

	const openShareholderLimitationInfoModal = () => {
		openModal(COMPANY_INVESTMENTS_LIMITATIONS_MODAL);
	};

	const renderButtons = () => {
		return (
			<div className="i-content__tabs i-content__tabs--fix-padding">
				<ShareholdersToolbarControlsContainer />
			</div>
		);
	};

	const renderLeftButtons = () => {
		const basePath = getComponentBasePath();

		return (
			<div className="i-content__tabs i-content__tabs--fix-padding i-content__tabs--split">
				<div style={{ visibility: numOfInvestors ? "visible" : "hidden" }}>
					<Text
						tid="investments.shareholders.count_summary"
						values={{
							totalCount: numOfInvestors,
							currentCount: numOfActiveShareholders,
						}}
					/>
				</div>
				<div className="h-box">
					<ShareholderAddShareholderBtnContainer
						basePath={basePath}
						location={location}
						onClick={closeAdvancedFilter}
					/>
					<ShareholdersAdvancedFilterBtnContainer
						onClick={toggleAdvancedFilter}
					/>
				</div>
			</div>
		);
	};

	const hasReachedShareholderLimit = numOfInvestors > maxNumberOfShareholders;

	return (
		<div className="flex flex-col p-md h-full">
			<Margin bottom={4}>
				<Breadcrumbs>
					<BreadcrumbsItem
						itemName={<Text tid="shares.shareholders.breadcrumbs.title" />}
						isStatic={true}
					/>
				</Breadcrumbs>
			</Margin>
			{hasReachedShareholderLimit && (
				<>
					<Margin bottom={4}>
						<Alert mode="warning" icon>
							<Text
								tid="investments.alert.reached_max_number_of_shareholders"
								color="white"
							/>{" "}
							<span
								style={{ cursor: "pointer" }}
								onClick={openShareholderLimitationInfoModal}
							>
								<Text
									tag="span"
									tid="investments.alerts.reached_max_number_of_shareholders.link"
									color="white"
									hasUnderline
								/>
							</span>
						</Alert>
					</Margin>
					<ShareholderLimitationInfoModal />
				</>
			)}

			<div className="i-content__body">
				<div className="i-content__container">
					{renderLeftButtons()}
					<ScrollView noLeftMargin={true} showOnHover={true} autoHide={true}>
						<ShareholdersFilterAndSortContainer>
							<InvestorsList
								params={match.params}
								basePath={getComponentBasePath()}
								onInvestorClick={closeAdvancedFilter}
							/>
						</ShareholdersFilterAndSortContainer>
					</ScrollView>
				</div>
				{!filterIsOpen && match.params.id && <InvestorsDetails />}
				{filterIsOpen && (
					<ScrollView showOnHover={true} autoHide={true}>
						{renderButtons()}
						<ShareholdersAdvancedFilterContainer
							isOpen={filterIsOpen}
							onClose={toggleAdvancedFilter}
						/>
					</ScrollView>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.get("userObj"),
	company: state.company.company,
	visibleInvestors: state.investors.get("visibleInvestors"),
	selectedInvestor: state.investors.get("selectedInvestor"),
	hasAppliedFilters: state.investors.get("hasAppliedFilters"),
	numOfInvestors: (state.investors.get("list", List()) ?? List()).size,
	numOfActiveShareholders: state.investors.get("numOfActiveShareholders"),
});

const mapActionsToProps = {
	openModal,
};

/** Export */
export default connect(mapStateToProps, mapActionsToProps)(Investors);
