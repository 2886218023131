import React, { Component } from "react";
import { connect } from "react-redux";
import {
	FormattedMessage,
	FormattedHTMLMessage,
	injectIntl,
	intlShape,
} from "react-intl";
import { fromJS, Map, List } from "immutable";
import debounce from "lodash/debounce";
import ScrollView from "../../../../dumb-components/shared/layout/scroll-view/scroll-view";
import {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	deleteLastTransaction,
	cancelTemporaryTransaction,
} from "../../../../actions/transaction.actions";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../../../actions/notify.actions";
import Select from "../../../../dumb-components/fields/select";
import DatePicker from "../../../../dumb-components/shared/datepicker/datepicker";
import ToggleSwitch from "../../../../dumb-components/shared/toggle-switch/toggle-switch";
import Panel from "../../../../dumb-components/panel";
import Field from "../../../../dumb-components/fields/field";
import {
	validateTransactionDate,
	isRequired,
} from "../../../../modules/validation.module";
import immutableForm from "../../../framework/immutable-form";
import { getFullShareTypeLabel } from "../../../helpers/shares";
import ShareholderOptionRenderer from "../shares-setup/shareholder-options-renderer";
import ShareholderValueRenderer from "../shares-setup/shareholder-value-renderer";
import { formatSsnPretty } from "../../../helpers/users";
import TransactionToolbarControlsContainer from "../../../../containers/shares/transaction-toolbar-controls.container";

const transactionType = "CHANGE-SHARE-LETTER";

const initialTransaction = fromJS({
	type: transactionType,
	handlerData: {},
});

const de = debounce((func, type, transaction) => {
	func(type, transaction.toJS());
}, 1000);

class IssueShareCertificates extends Component {
	static propTypes = {
		intl: intlShape.isRequired,
	};

	state = {
		sequencesChanged: [],
	};

	componentDidMount = () => {
		const { fetchTemporaryTransaction } = this.props;
		fetchTemporaryTransaction(transactionType);
	};

	getShareholdersOptions = () => {
		const { investors, transaction } = this.props;
		const balances = transaction.get("balances");

		if (!investors) {
			return [];
		}

		const investorsArr = [];
		investors.forEach((obj) => {
			if (balances.get(obj.get("id"))) {
				const investorTypeOfOwner = obj.get("investorTypeOfOwner");
				const name = obj.getIn(["investorInformation", "name"]);
				const id = obj.getIn(["investorInformation", "id"]);
				let label = "";

				if (investorTypeOfOwner === "private") {
					label = `${name}[split-here]${formatSsnPretty(id)}`;
				} else {
					label = name;
				}

				investorsArr.push({
					value: obj.get("id"),
					label,
				});
			}
		});

		return investorsArr;
	};

	getSequences = (investmentId) => {
		const { transaction } = this.props;
		const sequences = transaction.get("sequences", List());

		return sequences
			.map((obj, index) => obj.set("index", index))
			.filter((obj) => {
				return obj.get("investmentId") === investmentId;
			});
	};

	getNumberOfLettersSent = (investmentId) => {
		const sequences = this.getSequences(investmentId);

		try {
			return sequences.filter((obj) => {
				return obj.get("letterSent");
			}).size;
		} catch (e) {
			return 0;
		}
	};

	onChange = (field, val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction } =
			this.props;
		const newTransaction = tmpTransaction.setIn(["handlerData", field], val);
		this.props.resetErrors("handlerData." + field);
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeDate = (val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction } =
			this.props;
		const newTransaction = tmpTransaction.set("date", val);
		this.props.resetErrors("date");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeShareholder = (val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction } =
			this.props;
		this.setState({ sequencesChanged: [] });

		let newTransaction = tmpTransaction.setIn(
			["handlerData", "investorId"],
			val,
		);
		newTransaction = newTransaction.removeIn(["handlerData", "type"]);
		newTransaction = newTransaction.removeIn(["handlerData", "sequences"]);

		this.props.resetErrors("handlerData.investorId");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onSubmit = () => {
		const { tmpTransaction, createTransaction } = this.props;

		if (this.props.validate(tmpTransaction)) {
			createTransaction(tmpTransaction.toJS());
		}
	};

	onToggleType = (val) => {
		const {
			tmpTransaction,
			transaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		const investmentId = tmpTransaction.getIn(["handlerData", "investorId"]);
		const sequences = transaction.get("sequences", List());
		let handlerDataSequences = List();
		const sequencesChanged = [];

		sequences.forEach((obj, index) => {
			if (obj.get("investmentId") === investmentId) {
				handlerDataSequences = handlerDataSequences.push(
					Map({
						index,
						letterSent: val,
					}),
				);

				sequencesChanged.push(index);
			}
		});

		this.setState({ sequencesChanged });

		let newTransaction = tmpTransaction.setIn(
			["handlerData", "sequences"],
			handlerDataSequences,
		);
		newTransaction = newTransaction.setIn(
			["handlerData", "type"],
			val ? "all" : "individual",
		);

		resetErrors("handlerData.sequences");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onToggleSequence = (sequenceIndex, val) => {
		const { tmpTransaction } = this.props;
		let handlerDataSequences = tmpTransaction.getIn(
			["handlerData", "sequences"],
			List(),
		);
		const index = handlerDataSequences.findIndex((obj) => {
			return obj.get("index") === sequenceIndex;
		});

		if (index >= 0) {
			handlerDataSequences = handlerDataSequences.setIn(
				[index, "letterSent"],
				val,
			);
		} else {
			handlerDataSequences = handlerDataSequences.push(
				Map({
					index: sequenceIndex,
					letterSent: val,
				}),
			);
		}

		this.setState((prevState) => {
			const sequencesChanged = prevState.sequencesChanged;

			if (sequencesChanged.indexOf(sequenceIndex) >= 0) {
				return { sequencesChanged };
			}

			sequencesChanged.push(sequenceIndex);
			return { sequencesChanged };
		});

		this.onChange("sequences", handlerDataSequences);
	};

	cancelTransaction = () => {
		this.props.cancelTemporaryTransaction(transactionType);
	};

	renderSequence = (sequence, index) => {
		const { tmpTransaction } = this.props;
		const { sequencesChanged } = this.state;
		const investmentId = tmpTransaction.getIn(["handlerData", "investorId"]);
		const seqFrom = sequence.get("sequenceFrom");
		const seqTo = sequence.get("sequenceTo");
		const sequenceIndex = index;
		const handlerDataSequences = tmpTransaction.getIn(
			["handlerData", "sequences"],
			List(),
		);

		if (investmentId !== sequence.get("investmentId")) {
			return null;
		}

		let letterSent = false;

		if (sequencesChanged.indexOf(sequenceIndex) === -1) {
			letterSent = sequence.get("letterSent");
		} else {
			handlerDataSequences.forEach((obj) => {
				if (
					obj.get("index") === sequenceIndex &&
					obj.get("letterSent") === true
				) {
					letterSent = true;
				}
			});
		}

		return (
			<div key={index}>
				<div className="list__item">
					<div className="list__item__text-area list__item__text-area--w120">
						<span className="list__item__input list__item__input--pad-lft list__item__input--pad-right">
							{seqFrom} - {seqTo}
						</span>
					</div>
					<div className="list__item__body">
						<span className="text--align-left">
							{getFullShareTypeLabel(sequence.get("type"))}
						</span>
					</div>
					<div className="list__item__text-area list__item__text-area--w90">
						<ToggleSwitch
							checked={letterSent}
							onChange={this.onToggleSequence.bind(this, sequenceIndex)}
						/>
					</div>
				</div>
			</div>
		);
	};

	renderSequences = () => {
		const { tmpTransaction, investors, transaction } = this.props;
		const investmentId = tmpTransaction.getIn(["handlerData", "investorId"]);
		const sequences = transaction.get("sequences", List());

		if (!investors) {
			return null;
		}

		const investor = investors.find((inv) => {
			return inv.get("id") === investmentId;
		});

		if (!investor) {
			return null;
		}

		const renderHeader = () => {
			return (
				<span>
					{investor.getIn(["investorInformation", "name"])}{" "}
					<span className="text--muted">
						{formatSsnPretty(investor.getIn(["investorInformation", "id"]))}
					</span>
				</span>
			);
		};

		return (
			<Panel renderHeader={renderHeader}>
				<div className="form-group col-md-12">
					<div className="list__list-header">
						<div className="list__list-header__text-area list__list-header__text-area--w120 list__list-header__text-area--pad-lft list__list-header__text-area--pad-right">
							<FormattedMessage id="shares.transactions.issue_share_certificates.sequence" />
						</div>
						<div className="list__list-header__text-area list__list-header__text-area--pad-lft list__list-header__text-area--pad-right">
							<FormattedMessage id="shares.class_of_shares" />
						</div>
						<div className="list__list-header__text-area list__list-header__text-area--w90 list__list-header__text-area--pad-right list__list-header__text-area--right"></div>
					</div>
					<div className="list list--striped list--table">
						{sequences.map(this.renderSequence)}
					</div>
				</div>
			</Panel>
		);
	};

	renderButtons = () => {
		const { tmpTransaction } = this.props;

		if (!tmpTransaction) {
			return null;
		}

		return (
			<div className="i-content__tabs i-content__tabs--fix-padding">
				<TransactionToolbarControlsContainer
					onSubmit={this.onSubmit}
					onCancel={this.cancelTransaction}
				/>
			</div>
		);
	};

	render = () => {
		const { tmpTransaction, errors, i18n } = this.props;
		const { sequencesChanged } = this.state;

		if (!tmpTransaction) {
			return null;
		}

		const investmentId = tmpTransaction.getIn(["handlerData", "investorId"]);
		const sequences = this.getSequences(investmentId);
		const shareholdersOptions = this.getShareholdersOptions();
		const handlerDataSequences = tmpTransaction.getIn(
			["handlerData", "sequences"],
			List(),
		);
		let allSelected = true;

		sequences &&
			sequences.forEach((seq) => {
				const index = seq.get("index");
				let letterSent = false;

				if (sequencesChanged.indexOf(index) === -1) {
					letterSent = seq.get("letterSent");
				} else {
					handlerDataSequences.forEach((obj) => {
						if (obj.get("index") === index && obj.get("letterSent") === true) {
							letterSent = true;
						}
					});
				}

				if (!letterSent) {
					allSelected = false;
				}
			});

		return (
			<div className="i-content__container">
				{this.renderButtons()}
				<ScrollView>
					<Panel tid="shares.issue_share_certificates.info_about_share_certificates">
						<Field
							name="date"
							tid="generic.date"
							className="col-md-5 col-md-offset-7"
							errors={errors}
						>
							<DatePicker
								hasError={errors ? errors.get("date", List()).size > 0 : false}
								calendarPlacement="bottom-end"
								value={tmpTransaction.get("date")}
								onChange={this.onChangeDate}
								language={i18n.language}
							/>
						</Field>

						<Field
							name="handlerData.investorId"
							errors={errors}
							tid="shares.transactions.issue_share_certificates.form.shareholder"
						>
							<Select
								placeholder={i18n.messages["select_placeholder"]}
								value={investmentId}
								options={shareholdersOptions || []}
								simpleValue
								onSelect={(val) => this.onChangeShareholder(val)}
								optionComponent={ShareholderOptionRenderer}
								valueComponent={ShareholderValueRenderer}
							/>
						</Field>

						{investmentId && (
							<div>
								<Field>
									<FormattedHTMLMessage id="shares.transactions.issue_share_certificates.form.information" />
								</Field>

								<Field
									name="handlerData.type"
									errors={errors}
									className="col-md-4"
								>
									<ToggleSwitch
										checked={allSelected}
										onChange={this.onToggleType}
									/>
								</Field>

								<Field>
									<FormattedHTMLMessage id="shares.transactions.issue_share_certificates.form.information2" />
								</Field>
							</div>
						)}
					</Panel>

					{this.renderSequences()}
				</ScrollView>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get("transaction"),
		tmpTransaction: state.transaction.getIn(
			["tmpTransaction", transactionType],
			initialTransaction,
		),
		investors: state.investors.get("list"),
		i18n: state.i18n,
	};
}

const mapActionsToProps = {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	addErrorNotification,
	addInfoNotification,
	deleteLastTransaction,
	cancelTemporaryTransaction,
};

const validators = fromJS({
	date: {
		tid: "generic.date",
		rules: [
			{ func: isRequired, message: "validation.is_required" },
			{
				func: validateTransactionDate,
				message:
					"validation.current_transaction_date_must_be_later_then_last_transaction",
			},
		],
	},
	"handlerData.investorId": {
		tid: "shares.transactions.issue_share_certificates.form.shareholder",
		rules: [{ func: isRequired, message: "validation.is_required" }],
	},
});

let issueShareCertificates = immutableForm(
	IssueShareCertificates,
	"bonusIssue",
	validators,
);
issueShareCertificates = injectIntl(issueShareCertificates);

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(issueShareCertificates);
