import React from "react";
import { bool, func, string } from "prop-types";
import PromotingStepsDialog from "../promoting-steps-dialog";
import documentImg from "../../../../app/assets/images/promotion/document.png";
import protocolImg from "../../../../app/assets/images/promotion/protocol.png";
import shareIssuanceImg from "../../../../app/assets/images/promotion/shareissuance.png";
import eSigningImg from "../../../../app/assets/images/promotion/e-signing.png";
import subscribeImg from "../../../../app/assets/images/promotion/Subscribe.png";

import PromotingSlide from "../promoting-slide";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../actions/modals.actions";

function PromotingEsigningDialog() {
	const dispatch = useDispatch();

	const onClose = () => {
		dispatch(closeModal());
	};

	const renderSlide = (slide, changeStep, activeStep) => {
		return (
			<PromotingSlide
				leftNavigationItems={navigationItems}
				title={"documents.esigning.promotion.modal.left_title"}
				buttonTid={slides[slide].buttonTid}
				rightBottomTitle={slides[slide].rightBottomTitle}
				rightBottomBodyTidPartOne={slides[slide].rightBottomBodyTidPartOne}
				rightBottomBodyTidPartTwo={slides[slide].rightBottomBodyTidPartTwo}
				rightSideTopImg={slides[slide].rightSideTopImg}
				changeStep={changeStep}
				activeStep={activeStep}
				onClose={onClose}
			/>
		);
	};

	const steps = [
		{
			slots: {
				body: ({ changeStep, activeStep }) => {
					return <>{renderSlide("intro", changeStep, activeStep)}</>;
				},
			},
		},
		{
			slots: {
				body: ({ changeStep, activeStep }) => {
					return <>{renderSlide("documents", changeStep, activeStep)}</>;
				},
			},
		},
		{
			slots: {
				body: ({ changeStep, activeStep }) => {
					return <>{renderSlide("protocols", changeStep, activeStep)}</>;
				},
			},
		},
		{
			slots: {
				body: ({ changeStep, activeStep }) => {
					return <>{renderSlide("useCase", changeStep, activeStep)}</>;
				},
			},
		},
		{
			slots: {
				body: ({ changeStep, activeStep }) => {
					return <>{renderSlide("subscribe", changeStep, activeStep)}</>;
				},
			},
		},
	];

	return <PromotingStepsDialog isOpen={true} steps={steps} />;
}

PromotingEsigningDialog.propTypes = {
	isOpen: bool,
	closeModal: func,
	advertId: string,
	preview: bool,
};

export default PromotingEsigningDialog;

const navigationItems = [
	"documents.esigning.promotion.modal.left_nav_item.intro",
	"documents.esigning.promotion.modal.left_nav_item.documents",
	"documents.esigning.promotion.modal.left_nav_item.protocols",
	"documents.esigning.promotion.modal.left_nav_item.use_case",
	"documents.esigning.promotion.modal.left_nav_item.subscribe",
];

const slides = {
	intro: {
		buttonTid: "documents.promotion.modal.btn.next",
		rightBottomTitle: "documents.esigning.promotion.modal.left_nav_item.intro",
		rightBottomBodyTidPartOne:
			"documents.esigning.promotion.modal.right_bottom.body_1.intro",
		rightBottomBodyTidPartTwo:
			"documents.esigning.promotion.modal.right_bottom.body_2.intro",
		rightSideTopImg: eSigningImg,
	},
	documents: {
		buttonTid: "documents.promotion.modal.btn.next",
		rightBottomTitle:
			"documents.esigning.promotion.modal.left_nav_item.documents",
		rightBottomBodyTidPartOne:
			"documents.esigning.promotion.modal.right_bottom.body_1.documents",
		rightBottomBodyTidPartTwo:
			"documents.esigning.promotion.modal.right_bottom.body_2.documents",
		rightSideTopImg: documentImg,
	},
	protocols: {
		buttonTid: "documents.promotion.modal.btn.next",
		rightBottomTitle:
			"documents.esigning.promotion.modal.left_nav_item.protocols",
		rightBottomBodyTidPartOne:
			"documents.esigning.promotion.modal.right_bottom.body_1.protocols",
		rightBottomBodyTidPartTwo:
			"documents.esigning.promotion.modal.right_bottom.body_2.protocols",
		rightSideTopImg: protocolImg,
	},
	useCase: {
		buttonTid: "documents.promotion.modal.btn.next",
		rightBottomTitle:
			"documents.esigning.promotion.modal.left_nav_item.use_case",
		rightBottomBodyTidPartOne:
			"documents.esigning.promotion.modal.right_bottom.body_1.use_case",
		rightBottomBodyTidPartTwo:
			"documents.esigning.promotion.modal.right_bottom.body_2.use_case",
		rightSideTopImg: shareIssuanceImg,
	},
	subscribe: {
		buttonTid: "documents.promotion.modal.btn.done",
		rightBottomTitle:
			"documents.esigning.promotion.modal.left_nav_item.subscribe",
		rightBottomBodyTidPartOne:
			"documents.esigning.promotion.modal.right_bottom.body_1.subscribe",
		rightBottomBodyTidPartTwo:
			"documents.esigning.promotion.modal.right_bottom.body_2.subscribe",
		rightSideTopImg: subscribeImg,
	},
};
