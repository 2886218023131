import React from "react";
import { node } from "prop-types";

const Separator = ({ children }) => (
	<div className="flex items-center gap-2 w-full my-6">
		<div className="flex-1 border-t h-[1px]"></div>
		<div className="text-input">{children}</div>
		<div className="flex-1 border-t h-[1px]"></div>
	</div>
);

Separator.propTypes = {
	children: node,
};

export default Separator;
