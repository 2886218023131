import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, string } from "prop-types";
import { Map } from "immutable";
import DocumentsHelper from "../../../components/helpers/documents.helper";

import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import DropdownMenuContainer from "../../shared/dropdown-menu.container";
import CancelEsignOrPublishProcess from "./cancel-esign-or-publish-process.container";
import { OutlinedButton } from "../../../dumb-components/shared/button-v2";

import { DOCUMENT_ESIGN_USERS_MODAL } from "../../../constants/modals";
import { openModal } from "../../../actions/modals.actions";

import { OBJ_TYPE_DOCUMENT, OBJ_TYPE_MEETING } from "/shared/constants";

class MainProcessControlsContainer extends Component {
	static propTypes = {
		documentAllHaveSigned: bool,
		readOnly: bool,
		objType: string,
	};

	dropdownRef = null;

	toggleDropdown = () => {
		this.dropdownRef.onToggleMenu();
	};

	addSignees = () => {
		this.props.openModal(DOCUMENT_ESIGN_USERS_MODAL);
	};

	manageSignees = () => {
		this.toggleDropdown();
		this.props.openModal(DOCUMENT_ESIGN_USERS_MODAL);
	};

	render = () => {
		const {
			eSigning,
			eSignees,
			fileReference,
			documentAllHaveSigned,
			readOnly,
			objType,
		} = this.props;
		const noSigneesAdded = !eSignees.size;
		const documentIsSigned = DocumentsHelper.getDocumentIsSigned(
			Map({
				eSigning,
				eSignees,
				eSigningData: Map({ fileReference }),
			}),
		);

		if (readOnly || documentIsSigned || documentAllHaveSigned) {
			return null;
		}

		const disableDropdown = objType === OBJ_TYPE_DOCUMENT && noSigneesAdded;

		return (
			<>
				<Tooltip tid="document.esign.signatories.panel.tooltip.add_signatories">
					<OutlinedButton icon="faPlus" onClick={this.addSignees} />
				</Tooltip>

				<DropdownMenuContainer
					halignMenu="right"
					ref={(ref) => (this.dropdownRef = ref)}
					onClick={this.toggleDropdown}
					disabled={disableDropdown}
					renderRaw={
						<Tooltip
							active={disableDropdown}
							tid="document.esign.signatories.panel.tooltip.no_signees_added"
							delayShow="instant"
						>
							<OutlinedButton
								icon="faEllipsisV"
								disabled={disableDropdown}
								leftMargin
							/>
						</Tooltip>
					}
				>
					<Tooltip
						active={noSigneesAdded}
						tid="document.esign.signatories.panel.tooltip.no_signees_added"
						delayShow="instant"
					>
						<DropdownIconItem
							icon="faCog"
							tid="document.esign.signatories.panel.dropdown_item.manage_signees"
							onClick={this.manageSignees}
							disabled={noSigneesAdded}
						/>
					</Tooltip>

					{objType === OBJ_TYPE_MEETING && (
						<CancelEsignOrPublishProcess onClick={this.toggleDropdown} />
					)}
				</DropdownMenuContainer>
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		eSignees: store.documents.getIn(["document", "eSignees"], Map()),
		eSigning: store.documents.getIn(["document", "eSigning"]),
		fileReference: store.documents.getIn([
			"document",
			"eSigningData",
			"fileReference",
		]),
	};
};

const mapActionsToProps = {
	openModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(MainProcessControlsContainer);
