import React from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { array, func, number, string } from "prop-types";
import Button from "../../mui-components/button/button";
import CloseIcon from "@mui/icons-material/Close";

function PromotingSlide({
	leftNavigationItems,
	title,
	buttonTid,
	rightBottomTitle,
	rightBottomBodyTidPartOne,
	rightBottomBodyTidPartTwo,
	rightSideTopImg,
	changeStep,
	activeStep,
	onClose,
}) {
	const { t } = useTranslation();

	return (
		<Grid container sx={{ height: "100%" }}>
			<Grid
				item
				xs={3}
				sx={{
					display: "flex",
					flexDirection: "column",
					borderRight: "1px solid rgb(0,0,0,.07)",
					pt: 6,
					pr: 4,
					pb: 4,
					pl: 4,
				}}
			>
				<Typography variant={"h4"} sx={{ mb: 5 }}>
					{t(title)}
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
					}}
				>
					{leftNavigationItems.map((navItem, i) => {
						return (
							<Button
								key={i}
								disabled={activeStep === i}
								onClick={() => {
									changeStep(i);
								}}
							>
								<Typography
									variant={"subtitle2"}
									sx={{
										...(leftNavigationItems.length - 1 !== i && { mb: 1 }),
										...(activeStep === i && { fontWeight: "800" }),
									}}
								>
									{t(navItem)}
								</Typography>
							</Button>
						);
					})}
				</Box>
				<Box
					sx={{
						display: "flex",
						alignContent: "flex-end",
						flex: 1,
						flexWrap: "wrap",
					}}
				>
					<Button
						color={"positive"}
						onClick={() => {
							if (leftNavigationItems.length - 1 === activeStep) {
								console.log("closeModal");
								onClose && onClose();
							} else {
								changeStep(activeStep + 1);
							}
						}}
					>
						{t(buttonTid)}
					</Button>
				</Box>
			</Grid>
			<Grid item xs={9} sx={{ display: "flex", flexDirection: "column" }}>
				<Box sx={{ height: "440px", borderBottom: "1px solid rgb(0,0,0,.07)" }}>
					<IconButton
						onClick={() => {
							console.log("closeModal");
							onClose && onClose();
						}}
						sx={{ position: "absolute", right: "0px", color: "white" }}
					>
						<CloseIcon />
					</IconButton>
					<img
						src={rightSideTopImg}
						style={{ height: "100%", width: "100%", objectFit: "cover" }}
					/>
				</Box>
				<Box sx={{ padding: "32px" }}>
					<Typography variant={"h4"} sx={{ mb: 1 }}>
						{t(rightBottomTitle)}
					</Typography>
					<Typography variant={"body2"} sx={{ mb: 1 }}>
						<div
							dangerouslySetInnerHTML={{
								__html: t(rightBottomBodyTidPartOne),
							}}
						/>
					</Typography>
					<Typography variant={"body2"} sx={{ mb: 1 }}>
						<div
							dangerouslySetInnerHTML={{
								__html: t(rightBottomBodyTidPartTwo),
							}}
						/>
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
}

PromotingSlide.propTypes = {
	leftNavigationItems: array,
	title: string,
	buttonTid: string,
	rightBottomTitle: string,
	rightBottomBodyTidPartOne: string,
	rightBottomBodyTidPartTwo: string,
	rightSideTopImg: string,
	changeStep: func,
	activeStep: number,
	onClose: func,
};

export default PromotingSlide;
