import React from "react";
import { node, string } from "prop-types";
import { cva } from "class-variance-authority";

const controlSectionVariants = cva(
	"flex flex-col p-6 border rounded-lg gap-6",
	{
		variants: {
			variant: {
				default: "",
				destructive: "border-destructive text-destructive",
				warning: "border-warning text-warning",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);

const ControlSection = ({ title, children, variant }) => {
	return (
		<div className={controlSectionVariants({ variant })}>
			<div className="font-semibold">{title}</div>
			{children}
		</div>
	);
};

ControlSection.propTypes = {
	title: string,
	children: node,
	variant: string,
};

export default ControlSection;
