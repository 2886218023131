import React, { PureComponent } from "react";
import { func, bool } from "prop-types";
import styled from "styled-components";
import { list, map } from "react-immutable-proptypes";
import { getUserName } from "../../../components/helpers/users";

import ProfileImage from "../../shared/profile-image/profile-image";
import Icon from "../../shared/icon/icon";
import Text from "../../shared/text/text";
import Tooltip from "../../shared/tooltip/tooltip";
import investmentApi from "/shared/helpers/investment.helper";

const Signee = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 50px;
	width: 100%;
	background: ${(props) => props.theme.colors.white};
	cursor: default;
	margin-bottom: ${(props) => props.theme.spacing[2]};

	:last-child {
		margin-bottom: 0;
	}
`;

const Image = styled.div`
	min-width: 50px;
	width: 50px;
	height: 100%;
`;

const Status = styled.div`
	background: ${(props) =>
		props.isSigned
			? props.theme.colors.green
			: props.theme.colors.solitudeDark};
	display: flex;
	min-width: 50px;
	width: 50px;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

const Content = styled.div`
	flex: 0 1 auto;
	padding: ${(props) => props.theme.spacing[3]}
		${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[3]}
		${(props) => props.theme.spacing[4]};
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	min-width: 0;
`;

const Title = styled.div`
	display: flex;
`;

const Subtitle = styled.div`
	display: flex;
`;

const Warning = styled.div`
	min-width: 50px;
	max-width: 50px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Dropdown = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

export default class SigneeListItem extends PureComponent {
	static propTypes = {
		usersCache: map,
		investors: list,
		renderDropdown: func,
		item: map,
		noWarning: bool,
	};

	static defaultProps = {};

	render = () => {
		const { usersCache, investors, renderDropdown, item, noWarning } =
			this.props;
		const signeeId = item.get("userId");
		const profileImage = usersCache.getIn([signeeId, "image", "filename"]);
		const isSigned = item.get("status") === "SIGNED" ? true : undefined;
		const isNotified = item.get("notified") === true;
		const name = getUserName(item, investors, usersCache) || "";
		const statusIcon = isSigned ? "faCheck" : "faTimes";
		const investor = investors?.get(item.get("investmentId"));
		const investorEmail =
			investor && investmentApi.getInvestorEmail(investor.toJS());
		const email =
			item.get("email") ||
			usersCache.getIn([signeeId, "email"]) ||
			investorEmail;

		return (
			<Signee>
				<Image>
					<ProfileImage
						image={
							profileImage
								? `/api/users/public/images/${profileImage}-80x80?userId=${signeeId}`
								: null
						}
					/>
				</Image>

				<Status isSigned={isSigned}>
					<Icon icon={statusIcon} color="white" size="sml" type="solid" />
				</Status>

				<Content>
					<Title>
						<Text singleLine>{name}</Text>
					</Title>

					<Subtitle>
						<Text
							singleLine
							tid="documents.document.esign.signee.email"
							color="muted"
							values={{ email }}
						/>
					</Subtitle>
				</Content>

				{!isNotified && !noWarning && (
					<Warning>
						<Tooltip
							tid="documents.document.esign.signee.not_notified"
							delayShow="instant"
						>
							<Icon
								icon="faExclamationTriangle"
								size="sml"
								type="solid"
								color="orange"
							/>
						</Tooltip>
					</Warning>
				)}

				<Dropdown>{renderDropdown && renderDropdown(signeeId)}</Dropdown>
			</Signee>
		);
	};
}
